import React, { FC, useState } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import { countryOptions } from '@/utils/defaultData'
import { useHistory } from 'react-router';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DefaultInput } from '@/components';
import { checkDisabled } from '@/utils/mainUtils'
import './CreatAccount.scss'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import { useTranslation } from "react-i18next";
import { sendRegisterSms, submitRegister } from '@/api/register';
import { SAVE_USER_INFO } from '@/redux/action'
import _ from 'lodash';
import { useDispatch } from 'redux-react-hook';
import SendCodeCom from '@/components/SendCodeCom';
import moment from 'moment'
import { PASSWORD } from '@/utils/regular';

const { Option } = Select;
interface ICreatAccountProps {

}

const CreatAccount: FC<ICreatAccountProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [havePhone, setHavePhone] = useState<boolean>(true);
  const [isPhoneFocus, setIsPhoneFocus] = useState<boolean>(false);
  // const [codeVal, setCodeVal] = useState<string>('');
  const history = useHistory();
  const [sendLoading, setSendLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPwdError, setShowPwdError] = useState(false);

  const onFinish = async (values: any) => {
    try {
      const { password, passwordConfirm, userName, prefix, smsCode } = values || {};
      if (!validatePwdFn(password)) {
        return
      }
      if (password !== passwordConfirm) {
        message.warning(t('您的密码和确认密码不一致。'))
        return;
      }
      setSubmitLoading(true)
      const newValues = _.cloneDeep(values);
      Object.assign(newValues, {
        handPhone: prefix + '' + userName, userName: prefix + '' + userName
      })
      delete newValues.prefix;
      delete newValues.smsCode;
      const res = await submitRegister(newValues, { identifyingCode: smsCode })
      if (res) {
        setSubmitLoading(false)
        message.success(t('创建账号成功！'));
        // @ts-ignore
        const expires = new Date().toGMTString ? `expires=${moment().add(7, 'd').toDate().toGMTString()}` : ''
        document.cookie = `token=${res.token};path=/;${expires}`
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        dispatch({ type: SAVE_USER_INFO, userInfo: res.user })
        history.push('/register')
      }
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
    fn(value ? true : false)
  }

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ minWidth: 80 }}
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 250 }}
        onFocus={() => onInputFocus(setIsPhoneFocus, true)}
        onBlur={() => onInputFocus(setIsPhoneFocus, false)}
        onSelect={() => onInputFocus(setIsPhoneFocus, false)}
      >
        {
          countryOptions.map((item: any) => (
            <Option
              value={item.value}
              key={`${item.value}`}
              label={
                <div className='flex-center'>
                  <img
                    src={item.icon}
                    className="country-logo"
                    style={{ width: 21, marginRight: 5 }}
                    alt=''
                  /> <span>{item.code}</span>
                </div>
              }
            >
              <div className='flex-center'>
                <img
                  src={item.icon}
                  className="country-logo"
                  style={{ width: 24, marginRight: 8 }}
                  alt=''
                /> {item.label} {item.code}
              </div>
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  const validatePwdFn = (password: string) => {
    if (!PASSWORD.test(password)) {
      setShowPwdError(true)
      return false;
    }
    setShowPwdError(false)
    return true;
  }

  return (
    <div className='register-container login-form-container create-account-page'>
      <HeadComponent />
      <div className='register-wrapper'>
        <div className='register-box'>
          <div className='register-box-title'>{t('创建Wapi Pay账号')}</div>
          <Form
            form={form}
            onValuesChange={() => setIsUpdate(!isUpdate)} initialValues={{ prefix: 254 }}
            onFinish={onFinish}
            className="login-form"
          >
            <div className='form-item-warp'>
              <Form.Item
                name="userName"
                label=""
                className={`${!havePhone && 'form-item-tips'} ${isPhoneFocus && 'form-item-focus'}`}
              >
                <Input
                  placeholder={t('手机号码')}
                  addonBefore={prefixSelector}
                  className='phone-input'
                  onChange={(e) => {
                    onInputChange(e, setHavePhone)
                    // const { value } = e.target || {};
                    // form.setFieldsValue({ userName: value.replace(/[^\d]|^[0]/g,'') })
                  }}
                  type="number"
                  onFocus={() => onInputFocus(setIsPhoneFocus, true)}
                  onBlur={() => {
                    onInputFocus(setIsPhoneFocus, false)
                    const phone = form.getFieldValue('userName')
                    if (phone && phone.charAt(0) === '0') {
                      form.setFieldsValue({ userName: phone.slice(1) })
                    }
                  }}
                />
              </Form.Item>
              {
                !havePhone && (
                  <span className='tips'>
                    <ExclamationCircleOutlined /> {t('请输入您的手机号！')}
                  </span>
                )
              }
            </div>
            <SendCodeCom
              form={form}
              sendLoading={sendLoading}
              setSendLoading={setSendLoading}
              submitApi={sendRegisterSms}
            // sendCallback={(res) => setCodeVal(res)}
            />
            <DefaultInput
              formItemName="password"
              placeholder={t('密码')}
              isPassword
              tipsIsAbsolute={true}
              formItemTips={showPwdError ? t('密码格式不正确！') : t('请输入您的密码！')}
              showError={showPwdError}
              notes={t('密码应至少包含8个字符，包括1个大写字母、1个小写字母和1个数字。')}
              inputBlurFn={validatePwdFn}
            />
            <DefaultInput
              formItemName="passwordConfirm"
              formItemTips={t('请确认您的密码')}
              placeholder={t('确认密码')}
              isPassword
            />
            <div className='terms-box'>
              {t('注册即代表您同意我们的')}

              <span
                className='click-text'
                onClick={() => history.push('/terms')}
              >
                {t('使用条款')}
              </span>
              {t('及')}
              <span
                className='click-text'
                onClick={() => history.push('/privacyPolicy')}
              >
                {t('隐私政策')}
              </span>
            </div>
            <Button loading={submitLoading} disabled={!checkDisabled(form, 5)} type="primary" htmlType="submit" className='submit-button'>
              {t('创建账号')}
            </Button>
            <div className='login-text-warp'>
              {t('已有帐号？')}
              <span
                className='click-text'
                onClick={() => history.push('/login')}
              >
                {t('登录')}
              </span>
            </div>
          </Form>
        </div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default CreatAccount
