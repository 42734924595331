export const formatAmount = (amount: number) => {
  return Number(amount.toFixed(2))
}

/**
 * 解决两个数相乘精度丢失问题
 * @param a
 * @param b
 * @returns {Number}
 */
export function multiply(a: number, b: number, isFormatAmount = false) {
  let c = 0;
  const d = a.toString();
  const e = b.toString();
  try {
    c += d.split(".")[1].length;
  } catch (f) { }
  try {
    c += e.split(".")[1].length;
  } catch (f) { }
  const res = Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
  return isFormatAmount ? formatAmount(res) : res
}

export const add = function (a: number, b: number, isFormatAmount = false) {
  let c, d, e;
  if (undefined == a || null == a || isNaN(a)) { a = 0; }
  if (undefined == b || null == b || isNaN(b)) { b = 0; }
  try {
    c = a.toString().split(".")[1].length;
  } catch (f) {
    c = 0;
  }
  try {
    d = b.toString().split(".")[1].length;
  } catch (f) {
    d = 0;
  }
  e = Math.pow(10, Math.max(c, d));
  const res = (multiply(a, e, false) + multiply(b, e, false)) / e;
  return isFormatAmount ? formatAmount(res) : res
}

export const cut = function (a: number, b: number, isFormatAmount = false) {
  let c, d, e;
  if (undefined == a || null == a || isNaN(a)) { a = 0; }
  if (undefined == b || null == b || isNaN(b)) { b = 0; }
  try {
    c = a.toString().split(".")[1].length;
  } catch (f) {
    c = 0;
  }
  try {
    d = b.toString().split(".")[1].length;
  } catch (f) {
    d = 0;
  }
  e = Math.pow(10, Math.max(c, d));
  const res = (multiply(a, e, false) - multiply(b, e, false)) / e;
  return isFormatAmount ? formatAmount(res) : res
}

export const toFixed2 = (n: number) => {
  const m1 = Math.ceil(multiply(n, 100))
  return `${multiply(m1, 0.01)}`
}

export const toFixed6 = (n: number) => {
  let num = n
  const str = `${n}`
  const c = str.indexOf('.')
  if (str.length - c > 7) {
    num = Math.ceil(n * 1000000) / 1000000
  }
  return num;
}

export const computedRate = (rate: number) => {
  if (!rate) return 0
  // const r1 = toFixed6(rate);
  // return toFixed6(r1 * 1.01);
  return rate
}

export const fixed6ToFillZero = (n: number | string) => {
  const str = `${n}`
  const index = str.indexOf('.')
  if (~index) {
    const c = index + 7
    return str.padEnd(c, '0');
  }
  return str
}

export const formatToNumber = (str: string) => {
  const nums = str.split(',')
  const t = nums.reduce((prev, current) => prev + current)
  return t.replace('.00', '')
}