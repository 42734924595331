import {
  // individualMonetaryAttribute,
  // individualWapiAccount,
  // individualOtherPayMethod,
  businessMonetaryAttribute,
  businessWapiAccount,
  businessOtherPayMethod,
  QuickPayDelivery
} from './defaultData'
import { store } from '../index'
import { CustType } from '@/api/auth/auth'

export function signOut() {
  // @ts-ignore
  window.document.cookie = `token=1;expires=${new Date(0).toGMTString()}`
  const lsLocal = localStorage.getItem("wapipay-website-locale");
  window.localStorage.clear();
  localStorage.setItem("wapipay-website-locale", lsLocal || '');
}

// const controls = [
//   'undo', 'redo', 'separator',
//   'font-size', 'line-height', 'letter-spacing', 'separator',
//   'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
//   'superscript', 'subscript', 'remove-styles', 'emoji',  'separator', 'text-indent', 'text-align', 'separator',
//   'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
//   'link', 'separator', 'hr', 'separator',
//   'media', 'separator',
//   'clear'
// ]
const controls = [
  'undo', 'redo', 'remove-styles', 'separator',
  'font-size', 'bold', 'separator',
  'text-align', 'separator',
  'media', 'separator',
  'clear'
]

export const BraftEditorConfig = {
  controls,
  media: {
    // 配置允许插入的外部媒体的类型
    externals: {
      image: true,
      video: false,
      audio: false,
      embed: false
    },
    // 配置允许上传的媒体类型
    accepts: {
      image: true,
      video: false,
      audio: false,
    },
    pasteImage: true
  }
}

// 是否手机端
export const isMobile = () => {
  let WINDOW = false
  if (!window.navigator) {
    WINDOW = false
  } else {
    if (/Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent) || (document.body.clientWidth < 576)) {
      WINDOW = false
    } else {
      WINDOW = true
    }
  }
  return !WINDOW
}

export const Month = (m: string) => {
  let month = ''
  switch (m) {
    case '01':
      month = 'Jan'
      break;
    case '02':
      month = 'Feb'
      break;
    case '03':
      month = 'Mar'
      break;
    case '04':
      month = 'Apr'
      break;
    case '05':
      month = 'May'
      break;
    case '06':
      month = 'Jun'
      break;
    case '07':
      month = 'Jul'
      break;
    case '08':
      month = 'Aug'
      break;
    case '09':
      month = 'Sept'
      break;
    case '10':
      month = 'Oct'
      break;
    case '11':
      month = 'Nov'
      break;
    case '12':
      month = 'Dec'
      break;
    default:
      break;
  }
  return month
}

export const formatCurrency = (num: any) => {
  let str = `${num ?? 0}`
  if (str.indexOf('.') === -1) {
    str = str + '.00'
  } else if (str.indexOf('.') === str.length - 2) {
    str = str + '0'
  } else if (str.indexOf('.') < str.length - 3) {
    str = `${Math.floor((num ?? 0) * 100) / 100}`
  }
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getCurrentMonetaryAttribute = (deliveryMethod?: QuickPayDelivery) => {
  const { userInfo: { custType } = {} } = store.getState().UserInfoReducer
  switch (custType) {
    // case CustType.Individual:
    //   switch (deliveryMethod) {
    //     case QuickPayDelivery.WapipayAccount:
    //       return individualWapiAccount;
    //     case QuickPayDelivery.MPesa:
    //     case QuickPayDelivery.MTN:
    //     case QuickPayDelivery.Airtel:
    //     case QuickPayDelivery.LocalBank:
    //       return individualOtherPayMethod;
    //     default:
    //       return individualMonetaryAttribute
    //   }
    case CustType.Business:
      switch (deliveryMethod) {
        case QuickPayDelivery.WapipayAccount:
          return businessWapiAccount;
        case QuickPayDelivery.MPesa:
        case QuickPayDelivery.MTN:
        case QuickPayDelivery.Airtel:
        case QuickPayDelivery.LocalBank:
          return businessOtherPayMethod;
        default:
          return businessMonetaryAttribute
      }
  }
  return {}
}
