import React, { FC, useState } from 'react'
import { Form, Button, Select, message } from 'antd'
import { countryOptions } from '@/utils/defaultData'
import { DefaultInput, DefaultSelect, DefaultDatePicker } from '@/components';
import styles from './InformationCom.module.scss'
import { isMobile } from '@/utils/utils';
import { checkDisabled, regularData } from '@/utils/mainUtils';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment'
import { CustType } from '@/api/auth/auth'

const { Option } = Select;

interface IInformationComProps {
  InformationComLoading?: boolean;
  form: any;
  formData: any;
}

const InformationCom: FC<IInformationComProps> = (props) => {
  const { form, formData, InformationComLoading } = props;
  const { t, i18n } = useTranslation();
  const isCompany = formData?.custType !== CustType.Individual;

  return (
    <div className={styles.root}>
      <div className={styles.registerTitle}>{t(isCompany ? "太棒了告诉我们更多关于贵公司的情况" : "很好！请告诉我们更多关于您的信息")}</div>
      <div className={styles.subtitle}>{t(isCompany ? "别担心，我们会充分保护您的公司信息" : "别担心，我们将完全保护好您的个人信息")}</div>
      <div className='login-form-container'>
        <div className={styles.formTitle}>{t("账户信息")}</div>
        {
          isCompany ? (
            <>
              <DefaultInput
                formItemName="companyName"
                placeholder={t("公司名称")}
              />
              <DefaultInput
                formItemName="contactPerson"
                placeholder={t("联系人(业主/店员)")}
              />
              <DefaultInput
                formItemName="contactNumber"
                placeholder={t("联系电话")}
              />
              <DefaultInput
                formItemName="bankAccount"
                placeholder={t("银行账号")}
              />
              <DefaultInput
                formItemName="certOfIncorporationNumber"
                placeholder={t("公司注册编号")}
              />
              <DefaultInput
                formItemName="kraPinNumber"
                placeholder="KRA PIN"
              />
            </>
          ) : (
            <>
              <div className={styles.nameWarp}>
                <DefaultInput
                  formItemName="firstName"
                  placeholder={t("名字")}
                  tipsStyle={{ bottom: '-15px' }}
                />
                <DefaultInput
                  formItemName="lastName"
                  placeholder={t("姓氏")}
                  tipsStyle={{ bottom: '-15px' }}
                />
              </div>
              <DefaultDatePicker
                formItemName="birth"
                placeholder={t("出生日期")}
                disabledDate={(current: Moment) => {
                  return moment().diff(current, 'years') < 18
                }}
                popupStyle={{ width: isMobile() ? 'calc(100vw - 44px)' : '340px' }}
                bordered={false}
                defaultPickerValue={moment().add({ years: -18 })}
                otherProps={{
                  showToday: false
                }}
              />
            </>
          )
        }
        <DefaultInput
          formItemName="email"
          placeholder={t("电子邮箱")}
          regular={regularData.email}
          inputBlurFn={() => {
            const email = form.getFieldValue('email')
            if (email) {
              form.setFieldsValue({ email: email.toLowerCase() })
            }
          }}
        />
        <div className={styles.formTitle} style={{ marginTop: 40 }}>{t("个人信息")}</div>
        <DefaultSelect
          formItemName="nationality"
          placeholder={t("国家")}
          optionLabelProp="label"
        >
          {
            countryOptions.map((item: any) => (
              <Option
                value={item.codeVal}
                key={`${item.value}`}
                label={
                  <div className='flex-center'>
                    {/* <img
                      src={item.icon}
                      className="country-logo"
                      alt=''
                      style={{ height: 14, marginRight: 5 }}
                    />  */}
                    <span>{item.label}</span>
                  </div>
                }
              >
                <div className='flex-center'>
                  <img
                    src={item.icon}
                    className="country-logo"
                    alt=''
                    style={{ width: 24, marginRight: 8 }}
                  /> {item.label}
                </div>
              </Option>
            ))
          }
        </DefaultSelect>
        <DefaultInput
          formItemName="city"
          placeholder={t("城市")}
        />
        <DefaultInput
          formItemName="address1"
          placeholder={t("详细地址")}
        />
        <DefaultInput
          formItemName="postalCode"
          placeholder={t("邮政编码（可选）")}
          option={true}
        />
        <DefaultSelect
          formItemName="gender"
          placeholder={t("性别")}
        >
          <Option
            value="10"
          >
            {t("男性")}
          </Option>
          <Option
            value="20"
          >
            {t("女性")}
          </Option>
        </DefaultSelect>
        <Button
          disabled={regularData.email.test(form.getFieldValue('email')) ? !checkDisabled(form, isCompany ? 11 : 8, ['postalCode']) : true}
          type="primary"
          htmlType="submit"
          className='submit-button'
          loading={InformationComLoading}
        >
          {t("下一步")}
        </Button>
      </div>
    </div>
  )
}

export default InformationCom
