import React, { useState, useEffect, FC, useRef, useMemo } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { useLocation } from "react-router";
import styles from "./index.module.scss";
import { useMappedState, useDispatch } from "redux-react-hook";
import { Navbar, Nav, Image } from "react-bootstrap";
import HomePage from "./HomePage/HomePage";
import Remittance from "./Remittance/Remittance";
import MyAccounts from "./MyAccounts/MyAccounts";
import AccountDetail from "./MyAccounts/AccountDetail/AccountDetail";
import SubAccountDetail from "./MyAccounts/SubAccountDetail/SubAccountDetail";
import TopUp from "./MyAccounts/TopUp/TopUp";
import QuickPay from "./MyAccounts/QuickPay/QuickPay";
import Order from "./Order/Order";
import Recipient from './Recipient';
import BatchOrder from './BatchOrder';
import SubOrder from './BatchOrder/SubOrder';
import SubOrderDetail from './BatchOrder/SubOrder/SubOrderDetail';
import About from './About/About'
import Account from './Account/Account'
import ResetPin from './ResetPin'
import { IState } from "@/redux/interface";
import logoImg from "@/assets/components/nav-logo.svg";
import footerLogoImg from "@/assets/components/footer-logo.svg";
import selectArror from "@/assets/image/home/select-arrow.png"
import ForgetPassword from "./ForgetPassword";
import FooterComponent from '@/components/FooterComponent';
import { MenuOutlined, DownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Col, Row, Select } from "antd";
import { DELETE_USER_INFO, SET_REMIT_SYS_PARAMS, SET_BANK_DATA, SET_REMIT_PURPOSE } from "@/redux/action";
import closeIcon from "@/assets/components/close_white.png";
import { isMobile } from '@/utils/utils';
import { publicApi } from '@/api'
import footerIcon1 from "@/assets/image/home/footer-icon1.png";
import footerIcon2 from "@/assets/image/home/footer-icon2.png";
import footerIcon3 from "@/assets/image/home/footer-icon3.png";
import footerIcon4 from "@/assets/image/home/footer-icon4.png";
import footerIcon5 from "@/assets/image/home/footer-icon5.png";
import footerIcon6 from "@/assets/image/home/footer-icon6.png";
import footerIcon7 from "@/assets/image/home/footer-icon7.png";
import footerIcon8 from "@/assets/image/home/footer-icon8.png";
import { history } from "@/route";
import { getBankList } from '@/api/recipient/recipient';
import { useTranslation } from "react-i18next";
import { signOut } from '@/utils/utils'
import { RegistrationStatus } from "@/api/auth/auth";
import NavTransfer from '@/assets/components/nav-transfer.svg';
import NavOrder from '@/assets/components/nav-order.svg';
import BatchOrders from '@/assets/components/batch-order.svg';
import NavRecipient from '@/assets/components/nav-recipient.svg';
import NavAccounts from '@/assets/components/nav-accounts.svg';
import NavAbout from '@/assets/components/nav-about.svg';
import NavEn from '@/assets/components/nav-en.svg';
import HomeSVG from '@/assets/components/home.svg';
import { CustType } from '@/api/auth/auth'

interface ILocation {
  hash: string;
  pathname: string;
  search: string;
  state?: any;
}
const { Option } = Select;
const myPageRouteArr = ["myPage", "declare"];
const contactUsData = [
  { icon: footerIcon6, label: '地址', content: '702, Westside Towers Lower Kabete Road Westlands, Nairobi' },
  { icon: footerIcon7, label: '电话', content: '+254 (768 985998)' },
  { icon: footerIcon8, label: '电子邮箱', content: 'jambo@wapipay.com' }
]
const linkData = [
  { icon: footerIcon1, src: 'https://www.facebook.com/WapiPayltd' },
  { icon: footerIcon2, src: 'https://twitter.com/wapi_pay?s=21' },
  { icon: footerIcon3, src: 'https://instagram.com/wapi_pay?utm_medium=copy_link' },
  { icon: footerIcon4, src: 'https://www.linkedin.com/company/wapipay' },
  // {icon: footerIcon5, src: 'https://www.baidu.com/'},
]
const { SubMenu } = Menu;
const Index: FC = (props) => {
  const { t, i18n } = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation<ILocation>();
  const currentRoute =
    pathname?.split("/").length > 0 ? pathname?.split("/")[1] : "";

  const [locale, setLocale] = useState("zh");
  const { registrationStatus, custType } = userInfoState || {};

  const navData = [
    { routeName: 'home', label: '首页', icon: <img className={styles.NavIcon} src={HomeSVG} style={{ transform: 'translateY(1px)' }} /> },
    { routeName: 'myAccounts', label: '钱包', icon: <img className={styles.AccountsIcon} src={NavAccounts} />, hide: custType === CustType.Individual || !userInfoState.id },
    { routeName: 'remittance', label: '汇款', icon: <img className={styles.NavIcon} src={NavTransfer} /> },
    { routeName: 'order', label: '订单', icon: <img className={styles.NavIcon} src={NavOrder} /> },
    { routeName: 'batchOrders', label: '批量订单', icon: <img className={styles.NavIcon} src={BatchOrders} /> },
    { routeName: 'recipient', label: '收款人', icon: <img className={styles.NavIcon} src={NavRecipient} /> },
    { routeName: 'about', label: '关于', icon: <img className={styles.NavIcon} src={NavAbout} /> },
  ]

  const getSystemData = async () => {
    const data = await publicApi.getRemitSysParam()
    dispatch({ type: SET_REMIT_SYS_PARAMS, remitSysParams: data ?? {} })
  }
  const getBankListFn = async () => {
    getBankList()
      .then(({ list }) => {
        const data = list && list.length ? list : [];
        dispatch({ type: SET_BANK_DATA, bankData: data ?? {} })
      })
  }

  const getConfigData = async () => {
    const { list } = await publicApi.getConfiguration({})
    const reasonOptions = list.filter(item => item.name === 'REASON')
    const source = list.filter(item => item.name === 'SOURCEOFFUND')
    const remitPurpose = list.filter(item => item.name === 'PURPOSEOFREMIT')
    dispatch({
      type: SET_REMIT_PURPOSE,
      remitPurpose: remitPurpose,
      sourceOfFund: source,
      reasonOptions
    })
  }

  useEffect(() => {
    let lsLocal = localStorage.getItem("wapipay-website-locale");
    setLocale(lsLocal || "zh");
    getSystemData();
  }, []);
  const onLocaleChange = (value: string) => {
    i18n.changeLanguage(value);
    setLocale(value);
    localStorage.setItem("wapipay-website-locale", value);
  };

  useEffect(() => {
    getBankListFn()
    getConfigData()
  }, [i18n.language])

  function linkTo(route: string) {
    history.push(route);
  }
  function overrideToggle() {
    setExpanded((prevExpanded) => !prevExpanded);
  }

  const menuData = [{ label: registrationStatus === RegistrationStatus.Finished ? t("我的账户") : '完成注册', icon: "", url: registrationStatus === RegistrationStatus.Finished ? "/account" : '/register' }, { label: t("修改密码"), icon: "", url: "/forget" }];

  const menu = (
    <Menu>
      {menuData.map((item) => (
        <Menu.Item
          key={item.url}
          onClick={() => {
            setExpanded(false);
            history.push(item.url);
          }}
        >
          <div className={styles.menuContent}>
            <img alt="" src={item.icon} className={styles.topMenuIcon} />
            {t(item.label)}
          </div>
        </Menu.Item>
      ))}
      <Menu.Item
        onClick={() => {
          setExpanded(false);
          signOut()
          history.replace('/login')
          dispatch({ type: DELETE_USER_INFO });
        }}
      >
        <div className={`${styles.menuContent} ${styles.menuSignOut}`}>{t("退出登录")}</div>
      </Menu.Item>
    </Menu>
  );

  const MyAccountMenu = () => <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
    <span
      className="ant-dropdown-link"
      onClick={(e) => e.preventDefault()}
    >{t("我的账户")}
      <img
        src={selectArror}
        style={{ position: 'relative', top: 0, left: 10, width: 12 }}
        alt=''
      />
    </span>
  </Dropdown>

  const navList = (
    <Menu className={styles.mobileNav} expandIcon={<span></span>}>
      {
        navData.map((item: any) => {
          return !item.hide && <Menu.Item key={item.routeName}>
            <a
              className={styles.mobileNavItem}
              target="_blank"
              onClick={() => {
                setExpanded(false);
                linkTo(`/${item.routeName}`);
              }} rel="noreferrer"
            >
              {item.icon} {t(item.label)}
            </a>
          </Menu.Item>
        })
      }
      <SubMenu
        popupOffset={[-8, -5]}
        title={<div className={styles.mobileNavItem}>
          <img src={NavEn} className={styles.NavIcon} style={{ marginLeft: 1 }} />
          <span>{t("语言")}</span>
          <img src={selectArror} className={styles.ArrorIcon} />
        </div>}>
        <Menu.Item onClick={() => onLocaleChange('en')}>English</Menu.Item>
        <Menu.Item onClick={() => onLocaleChange('zh')}>中文</Menu.Item>
      </SubMenu>
    </Menu>
  );
  return (
    <div className={styles.wrap}>
      <div className={`${styles.navContainer}`}>
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={0}>
            <div className={styles.mobileHeader}>
              <Dropdown overlay={navList} trigger={['click']}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <MenuOutlined />
                </a>
              </Dropdown>
              <Navbar.Brand
                onClick={() => {
                  linkTo("/home");
                }}
                className={styles.navBrand}
              >
                <Image src={logoImg} alt="user profile" className={styles.logo} />
                {/* <span>{publicConfig.name}</span> */}
              </Navbar.Brand>
              {
                userInfoState?.id ? <MyAccountMenu /> : <div
                  onClick={() => {
                    setExpanded(false);
                    linkTo("/login");
                  }}
                >
                  {t("登录")} / {t("注册")}
                </div>
              }
            </div>
          </Col>
          <Col xs={0} sm={0} md={24}>
            <div className={styles.pcHeader}>
              <Navbar.Brand
                onClick={() => {
                  linkTo("/home");
                }}
                className={styles.navBrand}
              >
                <Image src={logoImg} alt="user profile" className={styles.logo} />
              </Navbar.Brand>
              <Navbar.Collapse
                id="basic-navbar-nav"
                style={{ height: "100%", display: "flex", alignItems: "center" }}
              >
                <Nav className={`ml-auto ${styles.navLinkWrap}`}>
                  {
                    navData.map((item: any) => {
                      return !item.hide && <Nav.Link
                        className={currentRoute === item.routeName ? styles.acRoute : ""}
                        key={item.routeName}
                        onClick={() => {
                          setExpanded(false);
                          linkTo(`/${item.routeName}`);
                        }}
                      >
                        {" "}
                        {t(item.label)}
                      </Nav.Link>
                    })
                  }

                  {userInfoState?.id ? (
                    <Nav.Link
                      className={
                        myPageRouteArr.includes(currentRoute)
                          ? styles.acRoute
                          : ""
                      }
                    >
                      <MyAccountMenu />
                    </Nav.Link>
                  ) : (
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        linkTo("/login");
                      }}
                    >
                      {t("登录")} / {t("注册")}
                    </Nav.Link>
                  )}
                </Nav>
              </Navbar.Collapse>
              <Select
                suffixIcon={(<img src={selectArror} style={{ position: 'relative', top: '-5px', left: '5px', width: '14px' }} />)}
                defaultValue="zh"
                style={{
                  marginLeft: 30,
                  width: 96,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "right",
                  fontSize: '1rem'
                }}
                value={locale}
                onChange={(e) => onLocaleChange(e)}
                bordered={false}
              >
                <Option value="en">English</Option>
                <Option value="zh">中文</Option>
              </Select>
            </div>
          </Col>
        </Row>
      </div>
      {/* </Navbar> */}
      <Router history={history}>
        <Switch>
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/myAccounts/:id/quickpay" component={QuickPay} />
          <Route exact path="/myAccounts/:id/topup" component={TopUp} />
          <Route exact path="/myAccounts/subAccount/:id" component={SubAccountDetail} />
          <Route exact path="/myAccounts/:id" component={AccountDetail} />
          <Route exact path="/myAccounts" component={MyAccounts} />
          <Route exact path="/remittance" component={Remittance} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/recipient" component={Recipient} />
          <Route exact path="/about" component={About} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/reset" component={ResetPin} />
          <Route exact path="/forget" component={ForgetPassword} />
          <Route exact path="/batchOrders" component={BatchOrder} />
          <Route exact path="/batchOrders/subOrder" component={SubOrder} />
          <Route exact path="/batchOrders/subOrder/detail" component={SubOrderDetail} />
        </Switch>
      </Router>
      <div className={styles.footerContainer}>
        {
          isMobile() ? (
            <footer className={styles.footer}>
              <img src={footerLogoImg} alt="" className={styles.logoPic} />
              {
                contactUsData.map((item: any) => (
                  <div className={styles.contactLi} key={item.label}>
                    <img alt="" src={item.icon} className={styles.contactIcon} />
                    <div className={styles.contactContent}>{item.content}</div>
                  </div>
                ))
              }
            </footer>
          ) : (
            <footer className={styles.footer}>
              <div className={styles.footerWrapper}>
                <div className={styles.footerL}>
                  <img src={footerLogoImg} alt="" className={styles.logoPic} />
                  <div className={styles.introduction}>
                    {t("使移动支付平台与银行账户之间的操作更加便利。减少非洲和亚洲之间的支票、现金和银行转账支付的人工流程。")}
                  </div>
                  <div className={styles.iconList}>
                    {
                      linkData.map((item: any, index: number) => (
                        <a key={`icon-${index}`} href={item.src} target="_blank" rel="noreferrer">
                          <img alt="" src={item.icon} className={styles.linkIcon} />
                        </a>
                      ))
                    }
                  </div>
                </div>
                <div className={styles.footerR}>
                  <div className={styles.footerTitle}>{t("联系我们")}</div>
                  {
                    contactUsData.map((item: any) => (
                      <div className={styles.contactLi} key={item.label}>
                        <img alt="" src={item.icon} className={styles.contactIcon} />
                        <div className={styles.contactLiRight}>
                          <div className={styles.contactTitle}> {t(item.label)}</div>
                          <div className={styles.contactContent}>{item.content}</div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </footer>
          )
        }
        <FooterComponent className={styles.homeFooter} />
      </div>
    </div>
  );
};

export default Index;
