import React, { FC, useState } from 'react'
import styles from './BackButton.module.scss'
import BackIcon from '@/assets/image/login/back-icon.png';
import { history } from "@/route";
import { useTranslation } from "react-i18next";

interface IBackButtonProps {
}
const BackButton: FC<IBackButtonProps> = (props) => {
  const {
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.goBack}>
      <div className={styles.wrap} onClick={() => { history.goBack() }}>
        <img src={BackIcon} className={styles.backIcon} alt='' />
        <span>{t("返回")}</span>
      </div>
    </div>
  )
}

export default BackButton