import React, { FC } from 'react'
import { Checkbox, Divider, Descriptions } from 'antd';
import moment from "moment";
import Exchange from "@/assets/image/order/exchange.png";
import Edit from "@/assets/image/remittance/edit.png";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { useRemittanceContext } from "@/pages/Remittance/RemittanceContext";
import { formatCurrency } from "@/utils/utils";
import { fixed6ToFillZero } from "@/pages/HomePage/utils";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import styles from "./Confirm.module.scss";
import { useHistory } from 'react-router';

interface IConfirmProps { }

const TransferKey = {
  recipientName: '收款人',
  remitPurposeName: '付款原因',
  sourceOfFundName: '资金来源',
  paymentTime: '付款时间',
  deliveryMethodName: '收款方式',
  paymentMethodName: '汇款方法',
}

const AmountKey = {
  sendingAmount: '汇款金额',
  instAmount: '收款金额',
  exchangeRate: '汇率',
  commission: '手续费',
}

const Confirm: FC<IConfirmProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { confirmVisible, setConfirmVisible, setStep, payment, setFinish, agree, setAgree } = useRemittanceContext();
  const onDetailEl = (label: string, line: boolean, dynamic: Record<string, string>) =>
    <div className={cls(styles.detail, !line ? styles.amountDetail : styles.infoDetail)}>
      <div className={cls(styles.label, !line ? styles.amountLabel : '')}>
        <span>{label}</span>
        <img src={Edit} onClick={() => { setStep(label === t("付款信息") ? 2 : 0) }} alt="" />
      </div>
      {line && <Divider />}
      <Descriptions column={1}>
        {
          Object.entries(dynamic).map(([key, value], index) =>
            <Descriptions.Item key={index} label={t(value)}>
              <span className={styles.desItem}>
                {key === 'deliveryMethodName' &&
                  <img src={payment.deliveryMethodIcon ?? ''} style={{ height: 26 }} alt="" />}
                {key === 'paymentMethodName' &&
                  <img src={payment.paymentMethodIcon ?? ''} style={{ height: 26 }} alt="" />}
                {key === 'exchangeRate' && <>
                  <span className={styles.value}>1 </span>
                  <span>{`${payment.instCurrency}`}</span>
                  <img src={Exchange} style={{ height: 18 }} alt="" />
                </>}
                <span className={styles.value}>
                  {key === 'paymentTime' && moment().format('DD-MM-YYYY')}
                  {key === 'exchangeRate' && `${fixed6ToFillZero(payment[key])}`}
                  {['sendingAmount', 'instAmount', 'commission'].includes(key) && formatCurrency(+payment[key])}
                  {!['sendingAmount', 'instAmount', 'commission', 'paymentTime', 'exchangeRate'].includes(key) && payment[key]}
                </span>
                {['sendingAmount', 'instAmount', 'commission', 'exchangeRate'].includes(key) && <span>
                  &nbsp;{key === 'instAmount' ? payment.instCurrency : payment.sendingCurrency}
                </span>}
              </span>
            </Descriptions.Item>)
        }
      </Descriptions>
      {!line && <div className={styles.total}>
        <>{t("总共应付")}</>
        <span>{`${formatCurrency(+payment.totalAmount)} ${payment.sendingCurrency}`}</span>
      </div>}
    </div>
  return (
    <div className={styles.confirmRoot}>
      {onDetailEl(t("付款信息"), true, TransferKey)}
      {onDetailEl(t("金额"), false, AmountKey)}
      <div className={styles.agree}>
        <Checkbox onChange={(c) => { setAgree(c.target.checked) }} checked={agree}>
          <span style={{ color: '#999' }}>{t("我同意Wapi Pay的条款和条件，包括：")}</span>
          <span
            className={styles.clickText}
            onClick={(e) => {
              e.preventDefault()
              window.open(window.location.origin + '/#/terms')
            }}
          >
            {t('使用条款')}
          </span>
          {t('及')}
          <span
            className={styles.clickText}
            onClick={(e) => {
              e.preventDefault()
              window.open(window.location.origin + '/#/privacyPolicy')
            }}
          >
            {t('隐私政策')}
          </span>
        </Checkbox>
      </div>
      <ConfirmModal
        visible={confirmVisible}
        title={payment.paymentMethodName}
        type={payment.paymentMethod}
        dataSource={payment}
        isPayment={true}
        setVisible={(finish: boolean) => {
          setConfirmVisible(false)
          setFinish(finish)
        }} />
    </div>)
}

export default Confirm; 