import React, { FC } from 'react'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import styles from './index.module.scss'

interface ITermsProps {

}

const Terms: FC<ITermsProps> = (props) => {

  return (
    <div className={styles.root}>
      <HeadComponent />
      <div className={styles.contentWarp}>
        <p className={styles.bigTitle}><span>Terms and Conditions for Transfer</span></p>
        <p className={styles.subTitle}><span>TERMS AND CONDITIONS FOR USE OF WAPIPAY SERVICES</span></p>
        <div className={styles.content}>
          <p><span>1.</span>Wapipay transactions can be sent and picked up at most Wapipay Agent (“Agent”) locations worldwide or through other channels such as mobile wallets, and bank deposits.</p>
          <p><span>2.</span>Money transfers may be paid in cash for amounts not exceeding USD $15,000 or its equivalent in currency of the operation, should be accompanied with supportive documentary evidence of reason for transfer.</p>
          <p><span>3.</span>Money transfers shall be paid to the person stipulated in the money transfer instructions provided by remitter, after verification of identity through examination of identification documents, including but not limited to name of sender, country of origin, name of beneficiary, approximate sum, the money transfer control number, identification test questions and any other conditions or requirements applicable at the Receiving Agent location. On certain occasions, customers may be required to provide additional identification or information.</p>
          <p><span>4.</span>The sender’s transaction data is confidential and the sender cannot share such data with any person other than the indicated beneficiary. In no event shall Wapipay or any of its agents be liable if the sender communicates transaction data to any person other than the intended beneficiary.</p>
          <p><span>5.</span>Wapipay is not responsible nor liable for any incorrect information submitted in the “Send Money” form or the “Receive Money” form during onboarding or sending transitions.</p>
          <p><span>6.</span>As part of the fight Against Money Laundering (AML) and the Financing of Terrorism Financing (FTF), Wapipay is required to screen all transactions against appropriate lists of names provided by the United Nations Security Council Committee and the governments of the countries and territories in which we operate. If a potential match is identified Wapipay is obligated to investigate the transaction to determine if the individual matched is in fact the individual on the relevant list.</p>
          <p><span>7.</span>Sending charges will be displayed to the customer at the time of initiating a transaction. The sender will bear all the transaction fees involved. Wapipay (or its Agents or mobile phone provider) may charge additional fees when funds are delivered through such media by the beneficiary.</p>
          <p><span>8.</span>All currency will be converted at the time of transfer. Wapipay calculates its rate of exchange based on the rate announced daily by the Central Bank of Kenya. The exchange rate, being subject to fluctuations, may be different than some publicly reported commercial exchange rates used in transactions between banks and other financial institutions.</p>
          <p><span>9.</span>Our customer care team may reach out to the customer via telephone informing them of the availability of the funds.</p>
          <p><span>10.</span>Wapipay offers free Short Messaging Service (“SMS”) notification in some cases to indicate that the transaction has been collected by the beneficiary or that funds are available for collection by the beneficiary. The same is offered when the transaction has been paid.</p>
          <p><span>11.</span>Wapipay will refund the principal amount of a money transfer (at the exchange rate applicable at the time the refund is made) upon a written request of the sender if payment to the beneficiary is not made within 90 (ninety) days. If the transfer is stopped at the sender’s request, transfer fees may not be refunded.</p>
          <p><span>12.</span>Payment of some money transfers may be delayed as a result of the application of applicable laws. Wapipay is authorized to deduct an administrative charge from money transfers that are not picked up within one year of the send date.</p>
          <p><span>13.</span>Wapipay does not guarantee the delivery of any goods by means of a Wapipay money transfer and such service shall be undertaken at the customers’ own risk.</p>
          <p><span>14.</span>Wapipay and its Agents reserve its right to refuse to accept any money transfers without assigning any reasons whatsoever.</p>
          <p><span>15.</span>When Wapipay or an Agent accepts a cheque draft, credit or debit card or other non-cash form of payment, neither Wapipay nor the Agent assumes any obligations to process or pay the money transfer if the form of payment is uncollectible</p>
          <p><span>16.</span>Personal Information (“Information”) of the parties to the transaction will be retained and processed by Wapipay under the applicable laws in order to execute the transaction for purposes such as administration, customer service, user validation, fraud prevention and product and business development activities.</p>
          <p><span>17.</span>Wapipay retains the Information and transaction history of the sender based on our record retention schedules for seven (7) years for the purposes for which the data was collected and in compliance with applicable laws. If the sender does not perform further transactions within the retention time period, the sender Information may be destroyed or deleted.</p>
          <p><span>18.</span>The customer consents to Wapipay, its officials, employees, correspondents, and Agents disclosing any information regarding the sender’s and/or beneficiary’s particulars, this application, the subject matter thereof and the customer’s accounts and affairs as Wapipay shall deem appropriate or as maybe required by law or any regulatory body for the purpose of any investigation relating to the money transfer applied for herein and any transaction connected therewith.</p>
          <p><span>19.</span>The government of Kenya enacted the Anti- Money Laundering Act to prevent banks and other institutions from being used, intentionally or unintentionally, by criminal elements for money laundering or terrorist financing activities. Additionally, these terms and conditions are subject to the laws of Kenya and the applicant submits to the exclusive jurisdiction of the Kenyan courts, as well as the legislative and legal requirements.</p>
          <p><span>20.</span>Wapipay reserves the right to add, alter, vary, and modify these terms and conditions or the offered services including the charges from time to time without prior notice.</p>
        </div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default Terms
