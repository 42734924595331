import React, { FC, useEffect, useState } from 'react'
import { Divider, message, Select } from 'antd';
import { useTranslation } from "react-i18next";
import { useRemittanceContext } from "@/pages/MyAccounts/QuickPay/QuickPayContext";
import { formatCurrency } from "@/utils/utils";
import CurrencyExchange from "../CurrencyExchange/CurrencyExchange";
import { quickPayDeliveryOptions, QuickPayDelivery } from '@/utils/defaultData';
import _ from 'lodash';
import styles from "./Amount.module.scss";
import rightIcon from '@/assets/image/accounts/right.svg';
import { accountApi } from '@/api'
import moment from 'moment';

interface IAmountProps {
  placement: string;
  detail?: accountApi.IAccountItem;
}

const { Option } = Select;

const Amount: FC<IAmountProps> = ({ placement, detail }) => {
  const { t } = useTranslation();
  const { payment, setPayment } = useRemittanceContext();

  const [dOps, setDOps] = useState(quickPayDeliveryOptions)

  const paymentLabel = {
    service: t("服务费"),
    pay: t("总付款金额"),
    receive: t("对方可收到"),
    deliveryDate: t("收款日期"),
  }
  const returnAmount = (key: string) => {
    let amount = '';
    if (key === 'service') {
      amount = formatCurrency(+payment.commission ?? 0);
    }
    if (key === 'pay') {
      amount = formatCurrency((+payment.sendingAmount ?? 0) + payment.commission);
    }
    if (key === 'receive') {
      amount = formatCurrency(+payment.instAmount ?? 0);
    }
    if (key === 'deliveryDate') {
      amount = moment().format('DD-MM-YYYY')
    }
    return amount
  }
  const optionItem = (v: any, sts: React.CSSProperties) =>
    <div className={`flex-center`} style={sts}>
      {/* <div className={styles.opImg}>
        <img src={v.icon} style={{ height: 26 }} alt="" />
      </div> */}
      <span>{v.label}</span>
    </div>

  const returnPaymentEl = () => Object.entries(paymentLabel).map(([key, value], index) =>
    <div key={key} className={styles.payment}>
      <span className={styles.paymentKey} style={{ fontSize: index === 1 ? 16 : 14 }}>{value}</span>
      <span className={styles.paymentValue}>
        <span style={index === 1 ? { fontSize: 22, fontWeight: 600 } : {}}>{returnAmount(key)} </span>
        {key !== 'deliveryDate' && <span>{key === 'receive' ? payment.instCurrency : payment.sendingCurrency}</span>}
      </span>
    </div>)

  useEffect(() => {
    setDOps(_.cloneDeep(quickPayDeliveryOptions))
  }, [payment.instCurrency])

  return (
    <div className={`${styles.amountRoot} flex-column-center`}>
      <div className={styles.content}>
        <CurrencyExchange onChange={(t) => { }} />
        <div className={styles.paymentMethod}>
          <span>{t("汇款方法")}</span>
          <div>
            <div>{detail?.name}</div>
            <div>{t("可用余额")}: {formatCurrency(detail?.balance || 0)} {detail?.currency}</div>
          </div>
        </div>
        <div className={styles.method}>
          <span className={styles.label}>{t("收款方式")}</span>
          <Select
            optionLabelProp="label"
            dropdownMatchSelectWidth={false}
            getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode || document.body}
            {...(payment.deliveryMethod ? { value: payment.deliveryMethod } : {})}
            onChange={(e: number) => {
              const current = dOps.filter(v => e === v.value)[0]
              // if (current.disabled) {
              //   message.warning(t(current.value === 2 ? 'M-PESA仅支持肯尼亚国家' : '即将推出...'))
              //   return
              // }
              setPayment({
                ...payment,
                deliveryMethod: e,
                deliveryMethodName: current.label,
                // deliveryMethodIcon: current.icon,
                beneficiaryNo: '',
                recipientName: ''
              })
            }}
          >
            {
              dOps.map(v => {
                let usableOptions = dOps.map(i => i.value)
                switch (payment.instCurrency) {
                  case 'KES':
                    usableOptions = [
                      QuickPayDelivery.WapipayAccount,
                      QuickPayDelivery.MPesa,
                      QuickPayDelivery.LocalBank,
                    ]
                    break;
                  case 'CNY':
                  case 'NGN':
                    usableOptions = [
                      QuickPayDelivery.WapipayAccount,
                      QuickPayDelivery.LocalBank,
                    ]
                    break;
                  case 'USD':
                  case 'GBP':
                    usableOptions = [
                      QuickPayDelivery.WapipayAccount,
                    ]
                    break;
                  case 'UGX':
                    usableOptions = [
                      QuickPayDelivery.WapipayAccount,
                      QuickPayDelivery.LocalBank,
                      QuickPayDelivery.MTN,
                      QuickPayDelivery.Airtel
                    ]
                    break;
                  default:
                }
                return <Option
                  value={v.value}
                  key={v.value}
                  disabled={!usableOptions.includes(v.value)}
                  {...({ label: optionItem(v, { height: '100%' }) })}>
                  {optionItem(v, { color: 'inherit' })}
                </Option>
              })
            }
          </Select>
        </div>
        <Divider />
        {returnPaymentEl()}
      </div>
    </div>
  )
}

export default Amount;