import React, { FC } from 'react'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import styles from './index.module.scss'

interface IPrivacyPolicyProps {

}

const PrivacyPolicy: FC<IPrivacyPolicyProps> = (props) => {

  return (
    <div className={styles.root}>
      <HeadComponent />
      <div className={styles.contentWarp}>

        <p className={styles.bigTitle}><span>Wapi Pay - DATA PROTECTION AND PRIVACY POLICY</span></p>

        <div className={styles.headWarp}>DATA PROTECTION AND PRIVACY POLICY (THE “PRIVACY POLICY”)</div>

        <section className={styles.section}>
          <div className={styles.subtitle}>1. Introduction</div>
          <p className={styles.content}>
            Wapi Pay respects the privacy of your personal information and will treat it with confidentiality and ensure its security. Wapi Pay takes all safeguards necessary to prevent unauthorised access and we do not divulge details collected from you as a visitor and/or customer to any third party unless you give us your consent to do so or unless we are obliged to do so by law or court order, or as otherwise set-out in the terms of this Privacy Policy. Any personal information provided by you to Wapi Pay will be used for the purpose of verifying your identity, providing and improving the products and services. And for other related purposes which may include planning , conducting and monitoring Wapi Pay business, updating and enhancing Wapi Pay records, understanding your financial needs, reviewing credit worthiness, debt collection purposes for crime/fraud prevention, advising you of other products and services which may be of interest to you  and for purposes required by law or regulation.
          </p>
          <p className={styles.content}>
            The information collected from you by Wapi Pay will be valuable in improving the design and marketing of our range of services and related products for customer use. Wapi Pay is committed to protecting your privacy and to preserve the confidentiality of all the information provided and in so doing Wapi Pay has developed this Data Protection and Privacy Policy. This Privacy Policy sets out our commitment to your privacy, the information that we may collect about you, what we use it for and your rights in relation to such information.
          </p>
        </section>

        <section className={styles.section}>
          <div className={styles.subtitle}>2. Personal Data Disclosure</div>
          <p className={styles.content}>
            {`Wapi Pay is bound, in accordance with the data privacy laws and regulations to observe secrecy and confidentiality with regards to all personal information shared with us. Other than to those individuals and entities listed below your details will not be revealed by Wapi Pay to any external body, unless Wapi Pay has your permission, or is under a legal obligation or any other duty to do so. For the purposes detailed above, your information may be disclosed to: `}
          </p>
          <ol className={styles.olList}>
            <li><span className={styles.olIndex}>a.</span>Other Branches or Companies in the Wapi Pay Group (ie. Wapi Pay, its subsidiaries and affiliates);</li>
            <li><span className={styles.olIndex}>b.</span>any regulatory, supervisory, governmental or quasi-governmental authority with jurisdiction over Wapi Pay Group members;</li>
            <li><span className={styles.olIndex}>c.</span>any agent, contractor or third party service provider, professional adviser or any other person under a duty of confidentiality to the Wapi Pay Group</li>
            <li><span className={styles.olIndex}>d.</span>credit reference agencies and, in the event of default, debt collection agencies</li>
            <li><span className={styles.olIndex}>e.</span>any actual or potential participant or sub-participant in, assignee, novatee or transferee of, any of the Wapi  Group's rights and/or obligations in relation to you;</li>
            <li><span className={styles.olIndex}>f.</span>any financial institution with which Wapi Pay has or proposes to have dealings</li>
          </ol>
          <p className={styles.content}>
            {`The above disclosures may require the transfer of your information to parties located in countries that do not offer the same level of data protection as your home country. However, Wapi Pay will ensure that parties to whom your details are transferred treat your information securely and confidentially. Wapi Pay also pledges its intention to fully meet any internationally recognised standards of personal data privacy protection and to comply with applicable data protection and privacy laws. `}
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>3. Processing and Retention of Data</div>
          <p className={styles.content}>
            We process and retain your data, including personal data in accordance with the Data Protection Act , to the extent that this is necessary for the appropriate conduct of our business relations and conforms to the applicable statutory provisions.  We only record information which serves to fulfil our duties and we do this solely within the scope of the service provided to you.  In providing our services we collect, process and store data relating to you from other institutions and other professionals.
          </p>
          <p className={styles.content}>
            Information held about you is retained as long as the purpose for which the information was collected continues. The information is then destroyed unless its retention is required to satisfy legal, regulatory or accounting requirements or to protect Wapi Pay’s interests. As a general rule, the maximum retention period is 7 years.
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>4. Why we Collect your Data</div>
          <p className={styles.content}>
            Information about you may be put onto the Wapi Pay’s database and used, analysed and assessed by Wapi Pay to provide you with a better service. We only collect information that we believe to be relevant and required to better conduct our business and to provide our customers with better products and services. We use the information that we collect about you in the following ways and for the following purposes:
          </p>
          <ol className={styles.olList}>
            <li><span className={styles.olIndex}>a.</span>for the provision of information or other services in relation to any specific requests that you may make to us, and to give you statements;</li>
            <li><span className={styles.olIndex}>b.</span>according to law, we may be required from time to time to disclose your information to Governmental bodies, agencies or our Regulators or recognised external authorities, but we will only do so under proper authority;</li>
            <li><span className={styles.olIndex}>c.</span>for internal assessment and analysis;</li>
            <li><span className={styles.olIndex}>d.</span>for research and statistics;</li>
            <li><span className={styles.olIndex}>e.</span>for the detection and prevention of fraud and any other illegal acts or criminal activity which Wapi Pay is bound to report and in line with our anti-money laundering policy;</li>
            <li><span className={styles.olIndex}>f.</span>to develop and improve Wapi Pay’s  products and services;</li>
            <li><span className={styles.olIndex}>g.</span>to check and verify your identity and address.</li>
          </ol>
          <p className={styles.content}>
            When vetting applications, we may carry out searches and identity checks. We may also request information on you from credit reference agencies, and use any of this information for identification purposes, debt tracing and the prevention of money laundering. The records will also be used for statistical analysis about credit, crime and fraud.
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>5. Third Party Access to your information </div>
          <p className={styles.content}>
            {``}
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}></div>
          <p className={styles.content}>
            We constantly strive to ensure that your information is kept safe and secure at all times. All our staff and all third parties with permitted access to your information are specifically required to observe this Privacy Policy. We aim to keep your information up to date and in this regard we may use third parties to process information on our behalf. We will only disclose personal data to third parties if this is required for the purpose of fulfilling any specific request that you may make to us or otherwise if we are to provide information if required by governmental bodies, agencies or regulators, however exclusively under proper authority, or if permitted in terms of this Privacy Policy. We will not process or provide third parties with information regarding your financial transactions / accounts held with us unless we are required or permitted to do so by law, by court order, with your consent or as otherwise set out in terms of this Privacy Policy. Whenever third parties process your information on our request we will bind them to keep such information in strict confidentiality. In the processing of information, your information shall at all times be kept protected by strict codes of secrecy and security to which we, our staff and third parties are subject to and will only be used in accordance with our instructions.
          </p>
          <p className={styles.content}>
            Personal data in relation to transactions effected via SWIFT (Society for Worldwide Interbank Financial Telecommunication) may be required to be disclosed to the United States authorities in order to comply with legal requirements applicable in the United States for the prevention of crime.
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>6. Direct Marketing</div>
          <p className={styles.content}>
            We may use your contact details and process your personal data to inform you of relevant opportunities, developments, events and products that may be of interest to you. We may carry out direct marketing in order to inform you, by mail, telephone, email or other electronic means, about other products and services provided by Wapi Pay, its subsidiaries, affiliates, associates, agents and by carefully selected third parties and for research purposes. Should you not want to be contacted for marketing purposes, you should inform us accordingly by ticking the appropriate box in the personal/corporate profile form or relevant application form or otherwise inform us by sending a written request to this effect ( by sending it to <a href="mailto:jambo@wapipay.com">jambo@wapipay.com</a> ).
          </p>
          <p className={styles.content}>
            We may require to contract third party companies to carry out bulk mailing or marketing campaigns on our behalf, in which case we would be required to provide them with your contact details excluding personal financial information. Such third party companies will be required to comply with all provisions of law including data protection when using information included in Wapi Pay’s mailing list.
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>7. Your Rights</div>
          <p className={styles.content}>
            You have rights under data protection laws in relation to your personal data. Please see below to find out more about these rights .You have the right to:
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>Request access to your personal data (commonly known as a "data subject access request").</span>
            This enables you to receive a copy of the personal data we hold about you. If you require this, then please reach out to us via <a href="mailto:jambo@wapipay.com">jambo@wapipay.com</a>
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>Request correction of the personal data that we hold about you.</span>
            This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us. If you require this, then please reach out to us via <a href="mailto:jambo@wapipay.com">jambo@wapipay.com</a>
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>Request erasure of your personal data.</span>
            This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons, which will be notified to you, if applicable, at the time of your request. As an MTO, Wapi Pay is under certain obligations to retain certain data for a minimum number of years. Please note that these retention requirements supersede any right to erasure requests under applicable data protection laws.
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>Object to processing of your personal data.</span>
            This is in situations where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information, which override your rights. As a licensed institution, Wapi Pay is under certain obligations to process and retain certain data for compliance purposes. Please note that these requirements supersede any right to objection requests under applicable data protection laws. If you object to the processing of certain data then we may not be able to provide our Services and it is likely we will have to terminate your account.
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>Request restriction of processing of your personal data.</span>
            This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. Please note that any requests in relation to the restriction of the processing of your data means that we may not be able to perform the contract we have or are trying to enter into with you (including  our Services). In this case, we may have to cancel your use of the Wapi Pay Services but we will notify you if this is the case at the time.
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>Request the transfer of your personal data to you or to a third party.</span>
            We will provide to you, your personal data in a structured, commonly used, machine-readable format, which you can then transfer to an applicable third party. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>Withdraw consent at any time where we are relying on consent to process your personal data.</span>
            {'\n'}However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide the our Services to you. We will advise you if this is the case at the time you withdraw your consent.
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>WHAT WE MAY NEED FROM YOU</span>
            {'\n'}We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>TIME LIMIT TO RESPOND</span>
            {'\n'}We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
          </p>
          <p className={styles.content}>
            <span className={styles.bold}>IF YOU FAIL TO PROVIDE PERSONAL DATA</span>
            {'\n'}Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you .In this case, we may have to cancel your use of our Services but we will notify you if this is the case at the time.
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>8. Internet Communications</div>
          <p className={styles.content}>
            In order to maintain the security of our systems, protect our staff, record transactions, and, in certain circumstances, to prevent and detect crime or unauthorised activities, Wapi Pay reserves the right to monitor all internet communications including web and email traffic into and out of its domains.
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>9. Cookies</div>
          <p className={styles.content}>
            <span className={styles.bold}>
              In order to improve our Internet service to you, we will occasionally use a "cookie" and/or other similar files or programs which may place certain information on your computer's hard drive when you visit an Wapi Pay web site. A cookie is a small amount of data that our web server sends to your web browser when you visit certain parts of our site. We may use cookies to:
            </span>
          </p>
          <ul className={styles.ulList}>
            <li>allow us to recognise the PC you are using when you return to our web site so that we can understand your interest in our web site and tailor its content and advertisements to match your interests (This type of cookie may be stored permanently on your PC but does not contain any information that can identify you personally.);</li>
            <li>Identify you after you have logged in by storing a temporary reference number in the cookie so that our web server can conduct a dialogue with you while simultaneously dealing with other customers. (Your browser keeps this type of cookie until you log off or close down your browser when these types of cookie are normally deleted. No other information is stored in this type of cookie.);</li>
            <li>allow you to carry information across pages of our site and avoid having to re-enter that information;</li>
            <li>allow you access to stored information if you register for any of our on-line services;</li>
            <li>enable us to produce statistical information (anonymous) which helps us to improve the structure and content of our web site;</li>
            <li>enable us to evaluate the effectiveness of our advertising and promotions.</li>
          </ul>
          <p className={styles.content}>
            We use two types of cookies:
          </p>
          <ol className={styles.olList}>
            <li><span className={styles.olIndex}>a.</span>Essential Cookies for the Internet Banking Service.  These are session cookies that expire at the end of the session (that is, once you log-out of the Internet Banking Service) or when the browser is closed.</li>
            <li><span className={styles.olIndex}>b.</span>Non-Essential Cookies. We use cookies provided by a service called ‘Google Analytics’; these cookies provide us with statistical information in an anonymous form about our website visitors.</li>
          </ol>
          <p className={styles.content}>
            Cookies do not enable us to gather personal information about you unless you give the information to our server. Most Internet browser software allows the blocking of all cookies or enables you to receive a warning before a cookie is stored. Most browsers are initially set to accept cookies. If you prefer, you can set your browser to block cookies. You can find information about how to change your browser settings as well as other useful information about cookies here: <a href="https://www.allaboutcookies.org" target='_blank'>www.allaboutcookies.org</a>. Google Analytics also allows you to opt out of its cookies permanently.
          </p>
          <p className={styles.content}>
            In order to do so please click on the following link: <a href="https://tools.google.com/dlpage/gaoptout" target='_blank'>https://tools.google.com/dlpage/gaoptout</a>.
          </p>
          <p className={styles.content}>
            If you do not adjust your browser settings to disable the use of cookies, then you are deemed to consent to Wapi Pay’s use of cookies for our website.
          </p>
        </section>
        <section className={styles.section}>
          <div className={styles.subtitle}>10. Contacting You</div>
          <p className={styles.content}>

            In providing your telephone, facsimile number, postal and e-mail address or similar details, you agree that Wapi Pay may contact you by these methods to keep you informed about Wapi Pay’s products and services or for any other reason. If you prefer not to be kept informed of Wapi Pay’s products and services, please contact Wapi Pay via Email.
          </p>
          <p className={styles.content}>
            Wapi Pay reserves the right to amend its prevailing Data Protection and Privacy Statement at any time and will place any such amendments on this Web Site. This Data Protection and Privacy Statement is not intended to, nor does it, create any contractual rights whatsoever or any other legal rights, nor does it create any obligations on Wapi Pay in respect of any other party or on behalf of any party.
          </p>
        </section>
      </div>
      <FooterComponent />
    </div>
  )
}

export default PrivacyPolicy
