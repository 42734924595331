import React, { FC, useEffect, useState, useMemo } from 'react'
import AccountDetail from '../AccountDetail/AccountDetail'

interface ISubAccountDetailProps {
}

const SubAccountDetail: FC<ISubAccountDetailProps> = (props) => {


  return (
    <AccountDetail isSubAccount={true} />
  )
}

export default SubAccountDetail
