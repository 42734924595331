import React, { FC, useEffect } from 'react'
import { Form, Select, Input, message, Upload } from 'antd'
import { useRemittanceContext } from "@/pages/Remittance/RemittanceContext";
import BankTransfer from "@/assets/image/remittance/BankTransfer.png";
import MPESA from "@/assets/image/remittance/MPESA.png";
import Cash from "@/assets/image/remittance/cash.png";
import Wechat from '@/assets/image/recipient/wechat.png'
import AliPay from "@/assets/image/order/alipay.svg";
import SelfiePhotoIcon from '@/assets/image/login/selfie-photo.png';
import type { IState } from "@/redux/interface";
import { useMappedState } from "redux-react-hook";
import styles from "./Reason.module.scss";
import { useTranslation } from "react-i18next";
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import type { UploadFile } from 'antd/lib/upload/interface.d';


interface IReasonProps { }

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const Reason: FC<IReasonProps> = () => {
  const { t } = useTranslation();
  const { payment, setPayment } = useRemittanceContext();
  const { remitPurpose, sourceOfFund } = useMappedState(
    (state: IState) => state.PublicConfigReducer
  );
  const [form] = Form.useForm();
  const option2 = [
    { id: 2, disabled: payment.sendingCurrency !== 'KES', text: "M-PESA", icon: MPESA },
    { id: 3, disabled: false, text: "Bank Transfer", icon: BankTransfer },
    { id: 1, disabled: true, text: "Wechat pay", icon: Wechat },
    { id: 4, disabled: true, text: "Alipay", icon: AliPay },
    { id: 6, disabled: true, text: "Cash", icon: Cash },
  ]

  const selects = [
    { label: '付款原因', placeholder: t('请选择付款原因'), option: remitPurpose, key: 'remitPurpose', name: 'remitPurposeName' },
    { label: '资金来源', placeholder: t('请选择资金来源'), option: sourceOfFund, key: 'sourceOfFund', name: 'sourceOfFundName' },
    { label: '汇款方法', placeholder: '', option: option2, key: 'paymentMethod', name: 'paymentMethodName' },
  ]
  const optionItem = (v: any, sts?: React.CSSProperties) =>
    <div className={`flex-center ${v.disabled ? styles.disabled : ''}`} style={sts}>
      <div className={styles.opImg}>
        <img src={v.icon} style={{ height: 26 }} alt="" />
      </div>
      <span>{v.text}</span>
    </div>

  const onFileChange = async (e: any) => {
    const { response, status } = e.file;
    if (status === 'done') {
      const { data = [] } = response || {}
      const { filePath } = data[0] ?? {}
      setPayment({ ...payment, productPictures: filePath })
    }
  }

  const uploadProps = {
    maxCount: 1,
    accept: '.PNG, .JPEG, .JPG, .png, .jpeg, .jpg',
    showUploadList: false,
    onChange: (file: UploadFile) => onFileChange(file)
  };

  useEffect(() => {
    if (!payment.remitPurpose || !payment.sourceOfFund) {
      setPayment({
        ...payment,
        remitPurpose: remitPurpose?.[0]?.id,
        remitPurposeName: remitPurpose?.[0]?.text,
        sourceOfFund: sourceOfFund?.[0]?.id,
        sourceOfFundName: sourceOfFund?.[0]?.text
      })
    }
  }, [])

  return (
    <div className={styles.reasonRoot}>
      <div className={styles.title}>{t("请完善付款信息")}</div>
      <Form form={form} layout="vertical">
        {
          selects.map(s => <Form.Item label={t(s.label)} key={s.key}>
            <Select
              optionLabelProp="label"
              dropdownMatchSelectWidth={false}
              placeholder={s.placeholder}
              getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode || document.body}
              {...(payment[s.key] ? { value: payment[s.key] } : {})}
              onChange={(e: any) => {
                const i = (s.option ?? []).findIndex((v: any) => e === v.id)
                setPayment({
                  ...payment,
                  [s.key]: e,
                  [s.name]: s.option[i]?.text,
                  paymentMethodIcon: s.key === 'paymentMethod' ? s.option[i]?.icon : payment.paymentMethodIcon
                })
              }}
            >
              {
                s.option.map((v: any) =>
                  <Option
                    value={v.id}
                    key={v.id}
                    disabled={s.key === 'paymentMethod' && v.disabled}
                    {...(s.key === 'paymentMethod' ? { label: optionItem(v, { height: '100%' }) } : { label: v.text })}>
                    {s.key === 'paymentMethod' ? optionItem(v, {}) : v.text}
                  </Option>)
              }
            </Select>
          </Form.Item>)
        }
        <Form.Item label={`${t('描述')} (${t('可选')})`}>
          <TextArea
            value={payment.productDescription}
            placeholder={`${t('产品描述')}...`}
            rows={5}
            onChange={(e) => setPayment({ ...payment, productDescription: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={`${t('收据图片')} (${t('可选')})`}>
          <UploadComponent {...uploadProps}>
            {
              payment.productPictures ? (
                <div className={`${styles.picWarp} flex-all-center`}>
                  <img src={payment.productPictures} className={styles.pic} alt='' />
                  <div className={styles.text}>{t("重新上传")}</div>
                </div>
              ) : (
                <div className={`${styles.picWarp} flex-all-center`}>
                  <div className={styles.picBox}>
                    <img src={SelfiePhotoIcon} className={styles.icon} alt='' />
                    <div className={styles.fileName}>{t('上传小于20Mb的图片')}</div>
                  </div>
                </div>
              )
            }
          </UploadComponent>
        </Form.Item>
      </Form>
    </div>
  )
}


export default Reason;