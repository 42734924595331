import React, { FC, useState, useMemo, useEffect } from 'react'
import { Button, Select, Form, message, Input, Spin } from 'antd';
import styles from './index.module.scss'
import { Modal } from '@/components';
import { deliveryOptions, quickPayCountryOptions, quickPayDeliveryOptions, QuickPayDelivery } from '@/utils/defaultData';
import { DefaultInput, DefaultSelect, SelectPicture } from '@/components';
import { checkDisabled, regularData } from '@/utils/mainUtils';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { recipientApi } from '@/api'
import { isMobile } from '@/utils/utils';

const { Option } = Select;

interface IUpdateRecipientProps {
  setIsModalVisible: (flag: boolean) => void;
  isViewRecipient?: boolean;
  getList?: (current?: number, pageSize?: number) => void;
  pageSize?: number;
  onSuccess?: (firstName: string, lastName: string, beneficiaryNo: string) => void;
  receiveType?: number;
  country?: string;
}

const AddRecipient: FC<IUpdateRecipientProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { setIsModalVisible, getList, pageSize, onSuccess, isViewRecipient } = props;
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bankData, setBankData] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [idType, setIdType] = useState(1);

  const isDisabled = false;
  const onFinish = async (values: any) => {
    const { receiveType, bankCode, prefix, country, recipientUserId } = values || {};

    if (receiveType === 2 && country !== 2) {
      message.warning(t("抱歉，M-PESA不适用于此收件人的国家/地区。"))
      return;
    }
    try {
      setIsLoading(true);
      const { label } = quickPayCountryOptions.find((item: any) => item.codeVal === country) || {};
      const { bankName } = bankData.find((item: any) => item.bankCode === bankCode) || {};
      const userItem = users.find(i => i.id === recipientUserId)
      if (!userItem || !userItem?.id) {
        message.warning(t('该用户未注册Wapi账号'))
        setIsLoading(false);
        return;
      }
      const handPhone = userItem.handPhone.replace(new RegExp('^' + prefix), '')
      const params: any = {
        recipientUserId: userItem.id,
        receiveType: receiveType + '',
        firstName: values.fullName,
        // lastName: values.lastName,
        // fullName: values.fullName,
        phoneNumber: userItem.handPhone,
        mobile: handPhone,
        countryCallingCode: prefix + '',
        country: label,
        countryId: props.country,
        idType: values.idType,
        idNumber: values.idNumber,
        email: values.email,
        address: values.address,
      }
      if (receiveType === QuickPayDelivery.LocalBank) {
        params.bankAccountNumber = values.accountNo
        params.bankName = bankName
        params.bankCode = values.bankCode
        params.bankBranch = values.bankBranch
        params.swiftCode = values.swiftCode
      }
      await recipientApi.addRecipient(params)
      setIsLoading(false);
      setIsModalVisible(false);
      getList?.(1, pageSize ?? 8);
      message.success(t('添加成功'));
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const { receiveType, country } = props
    if (country) {
      const item = quickPayCountryOptions.find(i => i.codeVal === +country)
      if (item) {
        form.setFieldsValue({ receiveType, country: item.codeVal })
        form.setFieldsValue({ prefix: item.value });
      }
    }
    getBanksList(props.country)
  }, [])

  const getBanksList = async (countryCode?: string) => {
    const data = await recipientApi.queryBankList({ countryCode })
    setBankData(data)
  }

  const getUsersList = async (keyword: string) => {
    setSearchKeyword(keyword)
    if (!keyword) {
      setUsers([])
      return
    }
    try {
      setFetching(true)
      const prefix = form.getFieldValue('prefix')
      const searchRes = await recipientApi.searchUser({ keyword: `${prefix}${keyword}` })
      setUsers(searchRes || [])
      setFetching(false)
    } catch (error) {
      setFetching(false)
    }
  };

  const fetchUsers = _.debounce(getUsersList, 800);

  return (
    // <div className={`${styles.root} ${currentRecipient && styles.detailPage}`}>
    <div className={`${styles.root}`}>
      <Modal
        title={t("添加收款人")}
        setIsModalVisible={setIsModalVisible}
        maskClosable={false}
      >
        <div className='login-form-container'>
          <Form
            form={form}
            onFinish={onFinish}
            className="login-form"
            onValuesChange={() => setIsUpdate(!isUpdate)}
            initialValues={{ prefix: 254, country: 2 }}
          >
            <div className={styles.formBox}>
              <DefaultSelect
                dropdownMatchSelectWidth={false}
                disabled={true}
                formItemLabel={isMobile() ? '' : t("收款方式")}
                formItemName="receiveType"
              >
                {
                  quickPayDeliveryOptions.map(v =>
                    <Option
                      value={v.value}
                      key={v.value}>
                      {v.label}
                    </Option>)
                }
              </DefaultSelect>
              <SelectPicture
                formItemName="country"
                placeholder={t("收款方国家")}
                formItemLabel={(Boolean(props.country) || isDisabled) && !isMobile() ? t("收款方国家") : ''}
                optionLabelProp="label"
                optionData={quickPayCountryOptions}
                valueKey="codeVal"
                // disabled={Boolean(props.country) || isDisabled || form.getFieldValue('receiveType') === 2}
                onChange={(val: any) => {
                  const { value } = quickPayCountryOptions.find((item: any) => item.codeVal === val) || {};
                  form.setFieldsValue({ prefix: value });
                  const newDeliveryOptions = _.cloneDeep(deliveryOptions);
                  newDeliveryOptions.forEach((item: any) => {
                    if (item.value === 2) {
                      item.disabled = val !== 2;
                    }
                  })
                  getBanksList(val)
                }}
              />
              <DefaultInput
                formItemName="fullName"
                placeholder={t("收款人姓名")}
                tipsStyle={{ bottom: '-15px' }}
                formItemLabel={isDisabled ? t("收款人姓名") : ''}
                disabled={isDisabled}
              />
              <div className={`form-item-warp ${styles.phoneWrap}`}>
                <Form.Item name="prefix" noStyle>
                  <Select
                    style={{ minWidth: 80 }}
                    optionLabelProp="label"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 250 }}
                  >
                    {
                      quickPayCountryOptions.map((item: any) => (
                        <Option
                          value={item.value}
                          key={`${item.value}`}
                          label={
                            <div className='flex-center'>
                              <img
                                src={item.icon}
                                className="country-logo"
                                style={{ width: 21, marginRight: 5 }}
                                alt=''
                              /> <span>{item.code}</span>
                            </div>
                          }
                        >
                          <div className='flex-center'>
                            <img
                              src={item.icon}
                              className="country-logo"
                              style={{ width: 24, marginRight: 8 }}
                              alt=''
                            /> {item.label} {item.code}
                          </div>
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <DefaultSelect
                  formItemName="recipientUserId"
                  placeholder={t('Wapi电话号码')}
                  formItemLabel={isDisabled ? t("Wapi电话号码") : ''}
                  disabled={isDisabled}
                  otherSelectProps={{
                    onSearch: fetchUsers,
                    filterOption: false,
                    notFoundContent: fetching ? <Spin size="small" /> : <div>{searchKeyword ? t('该用户未注册Wapi账号') : t('没有找到')}</div>,
                    showSearch: true,
                    style: {
                      width: '100%'
                    }
                  }}
                  otherFormItemProps={{
                    noStyle: true
                  }}
                  containerClassName={styles.phoneItem}
                >
                  {
                    users.map((item: any) => (
                      <Option
                        value={item.id}
                        key={item.id}
                      >
                        {item.firstName} {item.lastName} ({item.handPhone})
                      </Option>
                    ))
                  }
                </DefaultSelect>
              </div>
              {
                (props.receiveType === QuickPayDelivery.LocalBank || form.getFieldValue('receiveType') === QuickPayDelivery.LocalBank) && (
                  <>

                    <DefaultInput
                      formItemName="accountNo"
                      placeholder={t("账号号码")}
                      formItemLabel={isDisabled ? t("账号号码") : ''}
                      // inputType='number'
                      disabled={isDisabled}
                      regular={/^\d+$/}
                    />
                    <DefaultSelect
                      formItemName="bankCode"
                      placeholder={t("银行名称")}
                      formItemLabel={isDisabled ? t("银行名称") : ''}
                      disabled={isDisabled}
                    >
                      {
                        bankData.map((item: any) => (
                          <Option
                            value={item.bankCode}
                            key={item.id}
                          >
                            {item.bankName}
                          </Option>
                        ))
                      }
                    </DefaultSelect>
                    <DefaultInput
                      formItemName="bankBranch"
                      placeholder={t("银行支行")}
                      formItemLabel={isDisabled ? t("银行支行") : ''}
                      disabled={isDisabled}
                    />
                    <DefaultInput
                      formItemName="swiftCode"
                      placeholder={t("银行识别码")}
                      formItemLabel={isDisabled ? t("银行识别码") : ''}
                      disabled={isDisabled}
                    />
                  </>
                )
              }
              <DefaultSelect
                dropdownMatchSelectWidth={false}
                placeholder={t("身份证类型")}
                formItemName="idType"
                onChange={(v) => {
                  setIdType(v)
                }}
              >
                <Option value={1}>
                  {t("身份证")}
                </Option>
                <Option value={2}>
                  {t("护照")}
                </Option>
              </DefaultSelect>
              <DefaultInput
                formItemName="idNumber"
                placeholder={idType === 2 ? t("护照") : t("身份证号")}
                formItemTips={`${t("请输入")}${idType === 2 ? t("护照") : t("身份证号")}`}
              // formItemLabel={t("身份证号")}
              />
              <DefaultInput
                formItemName="email"
                placeholder={t("电子邮箱")}
                // formItemLabel={t("电子邮箱")}
                regular={regularData.email}
                inputBlurFn={() => {
                  const email = form.getFieldValue('email')
                  if (email) {
                    form.setFieldsValue({ email: email.toLowerCase() })
                  }
                }}
              />
              <DefaultInput
                formItemName="address"
                placeholder={t("地址")}
              // formItemLabel={t("地址")}
              />
            </div>
            <div className={styles.btnWarp}>
              <Button disabled={regularData.email.test(form.getFieldValue('email')) ? !checkDisabled(form, form.getFieldValue('receiveType') === QuickPayDelivery.LocalBank ? 13 : 9) : true} type="primary" htmlType="submit" loading={isLoading} className='submit-button'>
                {t("提交")}
              </Button>
            </div>

          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default AddRecipient
