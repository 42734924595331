import React, { FC, useState } from 'react'
import { Button, message } from 'antd'
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import styles from './index.module.scss'
import SelfiePhotoIcon from '@/assets/image/login/selfie-photo.svg';
import { useTranslation } from 'react-i18next';

interface ISelfiePhotoProps {
	setFileData: (arr: any[]) => void;
	fileData: any[];
	formData: any;
	submitLoading: boolean;
	onFileChange: (e: any, record: any, fileDataArr: any[], setFileDataArr: (arr: any[]) => void) => void;
}

const SelfiePhoto: FC<ISelfiePhotoProps> = (props) => {
	const { setFileData, fileData, submitLoading, onFileChange, formData } = props;
	const { t, i18n } = useTranslation();

	return (
		<div className={styles.root}>
			<div className={styles.registerTitle}>{t("自拍照")}</div>
			<div className={styles.subtitle}>{t("请手持身份证拍照，确保照片清晰")}</div>
			<div className='login-form-container'>
				<div className={`${styles.selectList}`}>
					{
						fileData.map((item: any) => (
							<div
								className={styles.selectLi}
								key={item.name}
							>
								<UploadComponent
									onChange={(e) => onFileChange(e, item, fileData, setFileData)}
									maxCount={1}
									showUploadList={false}
								>
									{
										item.src ? (
											<div className={styles.picWarp}>
												<img src={item.src} className={styles.pic} alt='' />
												<div className={styles.text}>{t("重新上传")}</div>
											</div>
										) : (
											<div className={styles.uploadWarp}>
												<div>
													<img src={SelfiePhotoIcon} className={styles.icon} alt='' />
													<div className={styles.fileName}>{item.name}</div>
												</div>
											</div>
										)
									}
								</UploadComponent>
							</div>
						))
					}
				</div>
				<Button disabled={fileData.filter((item: any) => item.src).length > 0 ? false : true} type="primary" loading={submitLoading} htmlType="submit" className='submit-button'>
					{t(formData?.custType === 3 ? "下一步" : "提交")}
				</Button>
			</div>
		</div>
	)
}

export default SelfiePhoto
