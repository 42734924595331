
import { SAVE_USER_INFO, DELETE_USER_INFO, IUserInfoActions } from '../action'
import { IUserInfo } from '@/api/auth/auth'
import { signOut } from '@/utils/utils'
import { message } from 'antd'
import i18next from "i18next";

export interface IUserInfoState {
  userInfo: IUserInfo & { isAgent: boolean; };
}

export interface ILoginState {
  login: {
    visible: boolean;
  }
}

let userInfo = null
// try {
//   const user = window.localStorage.getItem('userInfo')
//   if (user) {
//     userInfo = JSON.parse(user)
//   }
// } catch (error) {
//   message.warning(i18next.t("用户信息解析失败，请重新登录！"))
//   signOut()
//   setTimeout(() => {
//     window.location.reload()
//   }, 2000);
// }

export const initialState: IUserInfoState & ILoginState = {
  // @ts-ignore
  userInfo: userInfo || {},
  login: {
    visible: false
  }
}

const reducer = (state = initialState, action: IUserInfoActions & IUserInfoState) => {
  switch (action.type) {
    case SAVE_USER_INFO:
      return Object.assign({}, state, {
        userInfo: Object.assign({ isAgent: action?.userInfo?.custType === '2' }, action.userInfo),
      })
    case DELETE_USER_INFO:
      signOut()
      return Object.assign({}, state, {
        userInfo: {},
      })
    default:
      return state
  }
}
export default reducer