import React, { useContext } from 'react';
import type { SelectValue } from "antd/lib/select/index.d";

export interface QuickPayPayMent {
  sendingAmount: string;
  sendingCurrency: string;
  instAmount: string;
  instCurrency: string;
  paymentMethod: number;
  deliveryMethod: number;
  paymentRate: number;
  exchangeRate: number;
  commission: number;
  totalAmount: string;
  trnNo: string;
  id: number | undefined;
  paymentTime: string;
  recipientName: string;
  recipientId: number;
  beneficiaryNo: string;
  deliveryMethodName: string;
  deliveryMethodIcon: string | null;
  reason: number;
  source: number;
  reasonName: string;
  sourceName: string;
  agree: boolean;
  [key: string]: any;
}

export interface RemittanceContextProps {
  confirmVisible: boolean;
  setConfirmVisible: (t: boolean) => void;
  step: number;
  setStep: (t: number) => void;
  payment: QuickPayPayMent;
  setPayment: (t: QuickPayPayMent) => void;
  finish: boolean;
  setFinish: (t: boolean) => void;
}

export const QuickPayContext = React.createContext<RemittanceContextProps>({
  confirmVisible: false,
  setConfirmVisible: () => { },
  step: 0,
  setStep: () => { },
  payment: {
    sendingAmount: '',
    sendingCurrency: '',
    instAmount: '',
    instCurrency: '',
    paymentMethod: 3,
    deliveryMethod: 1,
    exchangeRate: 1,
    commission: 0,
    totalAmount: '',
    paymentTime: '',
    beneficiaryNo: '',
    paymentRate: 1,
    recipientName: '',
    recipientId: 0,
    deliveryMethodName: 'Bank Transfer',
    deliveryMethodIcon: '',
    trnNo: '',
    id: undefined,
    reason: 0,
    reasonName: '',
    source: 0,
    sourceName: '',
    agree: false
  },
  setPayment: () => { },
  finish: false,
  setFinish: () => { }
})

export function useRemittanceContext() {
  return useContext(QuickPayContext);
}