import React, { FC, useEffect, useState, useMemo } from 'react'
import { Button, message, Spin, Form, Select, DatePicker, Pagination } from 'antd';
import { useTranslation } from "react-i18next";
import { history } from "@/route";
import { useMappedState, useDispatch } from "redux-react-hook";
import type { IState } from "@/redux/interface";
import styles from './TopUp.module.scss'
import plusIcon from '@/assets/image/accounts/plus.svg';
import { accountApi, remittanceApi } from '@/api'
import { formatCurrency, getCurrentMonetaryAttribute } from "@/utils/utils";
import { computedRate } from "@/pages/HomePage/utils";
import { AccountType } from "@/utils/defaultData";
import { BackButton, NoDataCom, DefaultInput, DefaultSelect, Modal } from '@/components';
import _ from 'lodash';
import { getToken } from '@/request';
import { useParams } from "react-router";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

interface ITopUpProps {
}

const TopUp: FC<ITopUpProps> = (props) => {
  const { t } = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const [loading, setLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState('')
  let [detail, setDetail] = useState<accountApi.IAccountItem>()
  let [usableAccounts, setUsableAccounts] = useState<accountApi.IAccountItem[]>([])
  const currentAccountType = useMemo(() => {
    if (detail?.type === 'payout') {
      // pay out sub-account
      if (detail?.subAccountIdentifier) {
        return AccountType.PayoutSubAccount
      } else {
        return AccountType.PayoutAccount
      }
    } else {
      // collection sub-account
      if (detail?.subAccountIdentifier) {
        return AccountType.CollectionSubAccount
      } else {
        return AccountType.CollectionAccount
      }
    }
  }, [detail])
  const [paymentList, setPaymentList] = useState<accountApi.IPaymentMethodItem[]>([])
  const [paymentVisible, setPaymentVisible] = useState<boolean>(false);
  const [isPaymentPass, setIsPaymentPass] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [isMPesa, setIsMPesa] = useState<boolean>(false)
  const [rate, setRate] = useState(0)
  const params: { id: string } = useParams() || {}
  const accountId = params.id || ''
  const [isPass, setIsPass] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const monetaryAttribute = useMemo(() => {
    return getCurrentMonetaryAttribute()
  }, [])

  useEffect(() => {
    const token = getToken()
    if (token) {
      initData()
    }
  }, [])

  const initData = async () => {
    try {
      setLoading(true)
      const data = await accountApi.getAccountDetail(accountId)
      detail = data
      setDetail({ ...data })
      getAllAccounts()
      // Collection account get payment methods
      if (data?.type === 'collection' && !data.subAccountIdentifier) {
        const list = await queryPaymentMethods()
        setPaymentList(list)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getAllAccounts = async () => {
    try {
      const data = await accountApi.queryAccounts()
      // Payout subaccount
      if (detail?.subAccountIdentifier && detail?.type === 'payout') {
        const currencyItem = data.find(i => (i.currency === detail?.currency && i.type === 'collection'))
        if (currencyItem) {
          const res = await accountApi.getAccountDetail(currencyItem.id, { subAccountIdentifier: detail.subAccountIdentifier })
          if (res.subAccounts) {
            usableAccounts = res.subAccounts.filter(i => i.subAccountIdentifier === detail?.subAccountIdentifier)
            setUsableAccounts([...usableAccounts])
          }
        }
      } else if (detail?.type === 'payout' || detail?.subAccountIdentifier) {
        // Payout Account or Collection Sub-account
        const currencyItem = data.find(i => (i.currency === detail?.currency && i.type === 'collection'))
        if (currencyItem) {
          usableAccounts = [currencyItem]
          setUsableAccounts([...usableAccounts])
        }
      } else {
        // Collection account
        const currencyItems = data.filter(i => (i.type === 'payout' && i.balance > 0 && i.currency !== detail?.currency))
        usableAccounts = currencyItems
        setUsableAccounts([...usableAccounts])
      }
    } catch (error) {
    }
  }

  const queryPaymentMethods = async () => {
    const data = await accountApi.queryPaymentMethods()
    return data
  }

  if (!userInfoState.id) {
    return <div></div>
  }

  const onFinish = async (values: any) => {
    try {
      if (values.amount <= 0) {
        message.warning(t('充值金额低于最低限额，请调整'))
        return
      }
      if ((currentAccountType === AccountType.CollectionAccount || currentAccountType === AccountType.CollectionSubAccount) && detail?.currency) {
        // @ts-ignore
        const max = monetaryAttribute[detail?.currency]?.max
        if (max) {
          const usableAmount = (max - detail?.balance) || 0
          if (values.amount > usableAmount) {
            message.warning(t('充值金额超出最高限额，请调整'))
            return
          }
        }
      }
      setLoading(true);
      const params: accountApi.ITopUpReq = {
        amount: values.amount
      }
      if (isMPesa) {
        params.paymentMethodId = values.fromAccountId
      } else {
        params.fromAccountId = values.fromAccountId
      }
      await accountApi.accountTopUp(accountId, params)
      message.success(t("提交成功！"))
      history.goBack()
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const addPaymentFinish = async (values: any) => {
    try {
      setModalLoading(true);
      await accountApi.addPaymentMethod({
        "mobile": '254' + values.mobile,
        "type": values.type,
        "countryCallingCode": '254',
        "phoneNumber": values.mobile
      })
      message.success(t("添加成功！"))
      const data = await queryPaymentMethods()
      setPaymentList(data)
      paymentForm.resetFields()
      setModalLoading(false);
      setPaymentVisible(false);
    } catch (error) {
      setModalLoading(false);
    }
  }

  const getRate = async (accountId: number) => {
    try {
      const account = usableAccounts.find(i => i.id === accountId)
      if (!account || !detail) {
        return
      }
      const { list } = await remittanceApi.queryExchangeRate({
        localCurrency: detail.currency,
        foreignCurrency: account.currency,
        custType: userInfoState.custType
      })
      const r = list?.[0]?.exchangeRate
      const exchangeRate = computedRate(r);
      if (!exchangeRate) {
        setRate(0)
        message.warning(t('汇率查询失败'))
      } else {
        setRate(exchangeRate)
      }
    } catch (error) {
    }
  }

  const renderPaymentMethod = () => {
    const title = `${usableAccounts[0]?.name} (${t("收款账户")}) ${t("可用余额")} ${formatCurrency(usableAccounts[0]?.balance || 0)} ${usableAccounts[0]?.currency}`
    switch (currentAccountType) {
      case AccountType.PayoutAccount:
        return <Option
          value={usableAccounts[0]?.id}
        >
          <span title={title}>{title}</span>
        </Option>
      case AccountType.PayoutSubAccount:
        return <Option
          value={usableAccounts[0]?.id}
        >
          <span title={title}>{title}</span>
        </Option>
      case AccountType.CollectionAccount:
        return <>
          <OptGroup label='Accounts'>
            {usableAccounts.map((item) => {
              const title = `${item.name} (${t("支付账户")}) ${t("可用余额")} ${formatCurrency(item.balance || 0)} ${item.currency}`
              return <Option
                value={item.id}
                key={`account ${item.id}`}
              >
                <span title={title}>{title}</span>
              </Option>
            }
            )}
          </OptGroup>
          <OptGroup label='M-pesa'>
            {paymentList.map((item: accountApi.IPaymentMethodItem) => <Option
              value={item.id}
              key={`m-pesa ${item.id}`}
            >
              {item.type === 'm-pesa' ? 'M-pesa' : item.type}
              {` (****${item.mobile.slice(-4)})`}
            </Option>
            )}
          </OptGroup>
        </>
      case AccountType.CollectionSubAccount:
        return <Option
          value={usableAccounts[0]?.id}
        >
          {usableAccounts[0]?.name} ({t("收款账户")})
        </Option>
    }
  }

  const renderPaymentMethodName = () => {
    switch (currentAccountType) {
      case AccountType.PayoutAccount:
        return <span>{usableAccounts[0]?.name} ({t("收款账户")})</span>
      case AccountType.PayoutSubAccount:
        return <span>{usableAccounts[0]?.name} ({t("收款账户")})</span>
      case AccountType.CollectionAccount:
        const id = form.getFieldValue('fromAccountId')
        if (isMPesa) {
          const item = paymentList.find(i => i.id === id)
          return <span>
            {item?.type === 'm-pesa' ? 'M-PESA' : item?.type}
            {` (****${item?.mobile.slice(-4)})`}
          </span>
        } else {
          const item = usableAccounts.find(i => i.id === id)
          return <span>
            {item?.name} ({t("支付账户")})
          </span>
        }
      case AccountType.CollectionSubAccount:
        return <span>{usableAccounts[0]?.name} ({t("收款账户")})</span>
    }
  }

  const renderCurrentRate = () => {
    switch (currentAccountType) {
      case AccountType.PayoutAccount:
        return '1.00'
      case AccountType.PayoutSubAccount:
        return '1.00'
      case AccountType.CollectionAccount:
        if (isMPesa) {
          return '1.00'
        } else {
          return rate
        }
      case AccountType.CollectionSubAccount:
        return '1.00'
    }
  }

  const renderTotalAmount = () => {
    switch (currentAccountType) {
      case AccountType.PayoutAccount:
        return `${amount || 0} ${detail?.currency}`
      case AccountType.PayoutSubAccount:
        return `${amount || 0} ${detail?.currency}`
      case AccountType.CollectionAccount:
        if (isMPesa) {
          return `${formatCurrency(Number(amount || 0) * rate)} ${detail?.currency || ''}`
        } else {
          const id = form.getFieldValue('fromAccountId')
          const item = usableAccounts.find(i => i.id === id)
          return `${formatCurrency(Number(amount || 0) * rate)} ${item?.currency || ''}`
        }
      case AccountType.CollectionSubAccount:
        return `${amount || 0} ${detail?.currency}`
    }
  }

  const renderUsableAmount = () => {
    switch (currentAccountType) {
      case AccountType.PayoutAccount:
        return <div className={styles.row}>
          <span>&nbsp;</span>
          <span>{t("可用余额")}: {formatCurrency(usableAccounts[0]?.balance || 0)} {usableAccounts[0]?.currency}</span>
        </div>
      case AccountType.PayoutSubAccount:
        return <div className={styles.row}>
          <span>&nbsp;</span>
          <span>{t("可用余额")}: {formatCurrency(usableAccounts[0]?.balance || 0)} {usableAccounts[0]?.currency}</span>
        </div>
      case AccountType.CollectionAccount:
        if (isMPesa) {
          return ``
        } else {
          const id = form.getFieldValue('fromAccountId')
          const item = usableAccounts.find(i => i.id === id)
          return <div className={styles.row}>
            <span>&nbsp;</span>
            <span>{t("可用余额")}: {formatCurrency(item?.balance || 0)} {item?.currency}</span>
          </div>
        }
      case AccountType.CollectionSubAccount:
        return <div className={styles.row}>
          <span>&nbsp;</span>
          <span>{t("可用余额")}: {formatCurrency(usableAccounts[0]?.balance || 0)} {usableAccounts[0]?.currency}</span>
        </div>
    }
  }

  const computAmountRang = () => {
    if ((currentAccountType === AccountType.CollectionAccount || currentAccountType === AccountType.CollectionSubAccount) && detail?.currency) {
      // @ts-ignore
      const max = monetaryAttribute[detail?.currency]?.max
      if (max) {
        return <div
          className={styles.balance}
        >
          {t("充值金额范围")}: 1.00 - {formatCurrency((max - detail?.balance) || 0)} {detail?.currency}
        </div>
      }
    }
  }

  return (
    <Spin spinning={loading} tip="Loading">
      <BackButton />
      <div className={styles.root}>
        <div className={styles.title}>{detail?.name}</div>
        <div className='login-form-container'>
          <Form
            form={form}
            onFinish={onFinish}
            className="login-form"
            onValuesChange={(current, values) => {
              setIsPass(Boolean(values.amount && values.fromAccountId))
              setAmount(values.amount)
            }}
          >
            <DefaultInput
              formItemName="amount"
              formItemLabel={t("输入金额")}
              placeholder={`${t("金额")} (${detail?.currency})`}
              inputType='number'
            />
            <div className={styles.balance}>{t("可用余额")}: {formatCurrency(detail?.balance || 0)} {detail?.currency}</div>
            {computAmountRang()}
            <DefaultSelect
              formItemName="fromAccountId"
              formItemLabel={t("汇款方法")}
              onChange={(val, opt) => {
                const currentIsMPesa = Boolean(opt.key?.includes('m-pesa'))
                setIsMPesa(currentIsMPesa)
                if (!currentIsMPesa && currentAccountType === AccountType.CollectionAccount) {
                  getRate(val)
                } else {
                  setRate(1)
                }
              }}
            >
              {renderPaymentMethod()}
            </DefaultSelect>

            {currentAccountType === AccountType.CollectionAccount && <div
              className={styles.addPayment}
              onClick={() => {
                setPaymentVisible(true)
              }}
            >
              <img src={plusIcon} alt="" />
              {t("添加付款方式")}
            </div>}

            <div className={styles.info}>
              <div className={styles.row}>
                <span>{t("账户名称")}</span>
                <span>{detail?.name}</span>
              </div>
              <div className={styles.row}>
                <span>{t("汇款方法")}</span>
                {renderPaymentMethodName()}
              </div>
              {renderUsableAmount()}
              <div className={styles.row}>
                <span>{t("汇率")}</span>
                <span>{renderCurrentRate()}</span>
              </div>
              <div className={styles.row}>
                <span>{t("合计")}</span>
                <span>{renderTotalAmount()}</span>
              </div>
            </div>

            <Button
              disabled={!isPass}
              type="primary"
              shape="round"
              htmlType="submit"
              className={styles.btnWarp}
            >
              {t("立即充值")}
            </Button>
          </Form>

        </div>

      </div>
      {paymentVisible && <Modal
        title={t("添加付款方式")}
        setIsModalVisible={setPaymentVisible}
        maskClosable={true}
      >
        <div className='login-form-container'>
          <Form
            form={paymentForm}
            onFinish={addPaymentFinish}
            className="login-form"
            onValuesChange={(current, values) => {
              setIsPaymentPass(Boolean(values.mobile && values.type))
            }}
          >
            <div className={styles.formBox}>
              <DefaultSelect
                formItemName="type"
                formItemLabel={t("汇款方法")}
              >
                <Option
                  value='m-pesa'
                >
                  M-PESA
                </Option>
              </DefaultSelect>
              <DefaultInput
                formItemName="mobile"
                formItemLabel={t("输入M-pesa电话号码")}
                maxLength={50}
                inputType="number"
                placeholder={t("手机号码")}
                addonBefore={<span>+254</span>}
              />
            </div>
            <div className={styles.btnWarp}>
              <Button
                disabled={!isPaymentPass}
                type="primary"
                htmlType="submit"
                loading={modalLoading}
                className='submit-button'
              >
                {t("提交")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>}
    </Spin>
  )
}

export default TopUp
