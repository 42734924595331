import React, { useState, FC, useEffect } from "react";
import styles from "./HomePage.module.scss";
import { RateInputSelect } from "./components/RateInputSelect/RateInputSelect";
import { useMappedState } from "redux-react-hook";
import { IState } from "@/redux/interface";
import { Row, Col, Collapse, message, Button, Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { toFixed2, computedRate, formatToNumber, fixed6ToFillZero } from "./utils";
import { formatCurrency } from "@/utils/utils";
import HomePhone from "@/assets/image/home/homePhone.png";
import Exchange from "@/assets/image/home/exchange.png";
import HomePhoneH5 from "@/assets/image/home/homePhone-h5.png";
import iconApple from "@/assets/image/home/iconApple.png";
import iconGoogle from "@/assets/image/home/iconGoogle.png";
import { remittanceApi, accountApi } from '@/api'
import { history } from "@/route";
import { exchangeRateOptions } from "@/utils/defaultData";
import { CustType } from '@/api/auth/auth'
import { Modal, DefaultSelect } from '@/components';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

interface IHomepageProps { }

export const exchangeType: Record<string, string> = {
  CNY: 'KES',
  KES: 'CNY',
  USD: 'KES',
  ZK: 'KES',
  USH: 'KES',
  CEDI: 'KES',
  JPY: 'KES',
  INR: 'KES',
  IDR: 'KES',
  VND: 'KES',
  MYR: 'KES',
  PHP: 'KES',
  TWD: 'KES',
  EGP: 'KES',
  ZAR: 'KES',
  ETB: 'KES',
  Naira: 'USD',
}

const Homepage: FC<IHomepageProps> = (props) => {
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const { t } = useTranslation();
  const [Itype, setItype] = useState('number')
  const [sendingAmount, setPayAmount] = useState<string>('');
  const [instAmount, setReceiveAmount] = useState<string>('');
  const [sendingCurrency, setPaymentType] = useState<string>('CNY');
  const [instCurrency, setReceiveType] = useState<string>('KES');
  const [rateType, setRateType] = useState({
    paymentRate: 1,
    exchangeRate: 0,
  })
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payoutAccounts, setPayoutAccounts] = useState<accountApi.IAccountItem[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<number | undefined>();

  useEffect(() => {
    if (!sendingAmount) return
    const amount = toFixed2((+(formatToNumber(sendingAmount))) / rateType.exchangeRate);
    const value = Itype === 'number' ? amount : formatCurrency(+amount)
    setReceiveAmount(value)
  }, [rateType.exchangeRate])

  const getRate = async (foreignCurrency: string, localCurrency: string) => {
    if (sendingCurrency === instCurrency) {
      rateType.exchangeRate = 1
      setRateType({ ...rateType })
      return
    }
    const { list } = await remittanceApi.queryExchangeRate({
      localCurrency: sendingCurrency,
      foreignCurrency: instCurrency,
      custType: userInfoState.custType
    })
    const r = list?.[0]?.exchangeRate
    const exchangeRate = computedRate(r);
    if (!exchangeRate) {
      setRateType({ ...rateType, exchangeRate })
      setPayAmount('')
      setReceiveAmount('')
      message.warning(t('汇率查询失败'))
    } else {
      setRateType({ ...rateType, exchangeRate })
    }
  }

  const onExchange = () => {
    setPaymentType(instCurrency)
    setReceiveType(sendingCurrency)
  }

  const inputFormat = (type?: string) => {
    if (!sendingAmount) return;
    if (type === 'focus') {
      setItype('number')
      setReceiveAmount(formatToNumber(instAmount))
      setPayAmount(formatToNumber(sendingAmount))
    } else {
      setItype('text')
      setReceiveAmount(formatCurrency(+instAmount))
      setPayAmount(formatCurrency(+sendingAmount))
    }
  }

  useEffect(() => {
    getRate(sendingCurrency, instCurrency)
  }, [sendingCurrency, instCurrency])

  // const validSupport = (current: string, next: string) => {
  //   if ((current === 'CNY' && next === 'USD') || (next === 'CNY' && current === 'USD')) {
  //     return
  //   }
  //   const item = exchangeRateOptions.filter(t => t.value === current)[0]
  //   if (item && !item.support) {
  //     message.warning(t('即将推出...'))
  //   }
  // }

  return (
    <div className={styles.wrap}>
      <div className={styles.content1}>
        <Row className={styles.rateCounterWrap}>
          <Col xs={0} xxl={5} lg={2}></Col>
          <Col xs={24} xxl={8} lg={12} className={styles.headerCol}>
            <div className={styles.headerText}>
              {/* <div>
                The First <br />Africa-Asia <br />Payment Platform
              </div>
              <div>
                Make the most convenient payments to <br />and from mobile money platforms and<br /> bank accounts
              </div> */}
              <div className={styles.headerTextTitle}>
                {t('首个')} <br />{t('亚非')} <br />{t('支付平台')}
              </div>
              <div className={styles.headerTextTitleH5}>
                {t('首个')} {t('亚非')} <br />{t('支付平台')}
              </div>
              <div className={styles.headerTextDes}>
                {t('为移动货币平台')}<br />{t('和银行账户')}<br />{t('提供最便捷的支付服务')}
              </div>
            </div>
          </Col>
          <Col xs={24} xxl={6} lg={8} className={styles.headerCateCol}>
            <div className={styles.rateCounter}>
              <div className={styles.rHeader}>
                <div>{t("汇率查询")}</div>
                <div onClick={onExchange} className="flex-center">
                  <img style={{ width: 20, marginRight: 10 }} src={Exchange} alt="" />
                  <span>1 {sendingCurrency} = {rateType.exchangeRate ? fixed6ToFillZero(rateType.exchangeRate) : ''} {instCurrency}</span>
                </div>
              </div>
              <div className={styles.rBody}>
                <div>
                  <RateInputSelect
                    inputValue={sendingAmount}
                    defaultSelect={sendingCurrency}
                    type={instCurrency}
                    inputType={Itype}
                    onSelectChange={(e) => {
                      let next = instCurrency
                      // if (instCurrency === e) {
                      //   setReceiveType(exchangeType[e])
                      //   next = exchangeType[e]
                      // }
                      setPaymentType(e)
                      // validSupport(e, next)
                    }}
                    onInputBlur={() => inputFormat('blur')}
                    onInputFocus={() => inputFormat('focus')}
                    onInputChange={e => {
                      if (!rateType.exchangeRate) return;
                      setReceiveAmount(e ? toFixed2(+e * rateType.exchangeRate) : '')
                      setPayAmount(e)
                    }}
                    placeholder={t("汇款金额")}
                  />
                </div>
                <div style={{ marginTop: "22px" }}>
                  <RateInputSelect
                    inputValue={instAmount}
                    defaultSelect={instCurrency}
                    type={sendingCurrency}
                    inputType={Itype}
                    onSelectChange={(e) => {
                      let next = sendingCurrency
                      // if (sendingCurrency === e) {
                      //   setPaymentType(exchangeType[e])
                      //   next = exchangeType[e]
                      // }
                      setReceiveType(e)
                      // validSupport(e, next)
                    }}
                    onInputBlur={() => inputFormat('blur')}
                    onInputFocus={() => inputFormat('focus')}
                    onInputChange={e => {
                      if (!rateType.exchangeRate) return;
                      setPayAmount(e ? toFixed2(+e / rateType.exchangeRate) : '')
                      setReceiveAmount(e)
                    }}
                    placeholder={t("收款金额")}
                  />
                </div>
                <Button
                  loading={isLoading}
                  className={styles.sendButton}
                  onClick={async () => {
                    if (!userInfoState.id) {
                      message.warning(t('您未登录，请登录后操作！'))
                      return
                    }
                    if (userInfoState.custType === CustType.Individual) {
                      message.warning(t('请使用WapiPay App操作！'))
                      return
                    }
                    setIsLoading(true)
                    const data = await accountApi.queryAccounts({
                      leftJoinSubAccounts: true,
                      type: 'payout'
                    })
                    setIsLoading(false)
                    const currencyItem = data?.find(i => i.currency === sendingCurrency)
                    if (!currencyItem) {
                      message.warning(t('请开通该钱包账户的币种后操作！'))
                      return
                    }
                    if (userInfoState.custType === CustType.Business) {
                      history.push({
                        pathname: `/myAccounts/${currencyItem.id}/quickpay`,
                        state: {
                          sendingAmount: formatToNumber(sendingAmount),
                          instAmount: formatToNumber(instAmount),
                          sendingCurrency,
                          instCurrency,
                          formatSend: sendingAmount,
                          formatInst: instAmount,
                        }
                      });
                      return
                    }
                    if (userInfoState.custType === CustType.Agent) {
                      setPayoutAccounts(data)
                      setIsModalVisible(true)
                      return
                    }
                    // history.push({
                    //   pathname: "/remittance",
                    //   state: {
                    //     sendingAmount: formatToNumber(sendingAmount),
                    //     instAmount: formatToNumber(instAmount),
                    //     sendingCurrency,
                    //     instCurrency,
                    //     paymentRate,
                    //     exchangeRate
                    //   }
                    // });
                    // localStorage.setItem('home-incoming-rate', '1')
                  }}
                >
                  {t("立即汇款")}
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={0} xxl={5} lg={2}></Col>
        </Row>
      </div>
      <div className={styles.content2}>
        <Row style={{ width: "100%" }}>
          <Col xs={0} lg={5}></Col>
          <Col xs={24} lg={0} style={{ marginBottom: '20px' }}>
            <div>
              <div className={styles.title}>{t("首个亚非支付平台")}</div>
              <div className={styles.describe}>
                {t("使移动支付平台与银行账户之间的操作更加便利。减少非洲和亚洲之间的支票、现金和银行转账支付的人工流程。")}
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            lg={5}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className={styles.homePhoneImg} src={HomePhone} alt="" />
            <img className={styles.homePhoneH5Img} src={HomePhoneH5} alt="" />
          </Col>
          <Col xs={24} lg={0}>
            <div>
              <div className={styles.btnGroup}>
                <div className={styles.downLoadBtn}>
                  <img className={styles.icon} src={iconApple} alt="" />
                  <div className={styles.btnText}>
                    <div className={styles.downLoadText}>Download on the</div>
                    <div className={styles.platform}>App Store</div>
                  </div>
                </div>
                <div className={styles.downLoadBtn}>
                  <img className={styles.icon} src={iconGoogle} alt="" />
                  <div className={styles.btnText}>
                    <div className={styles.downLoadText}>Get it on</div>
                    <div className={styles.platform}>Google</div>
                  </div>
                </div>
              </div>
            </div>

          </Col>
          <Col xs={3} lg={2}></Col>
          <Col xs={3} lg={0}></Col>
          <Col xs={0} lg={8}>
            <div style={{ display: "flex", alignItems: "center", height: '100%' }}>
              <div style={{ maxWidth: "600px" }}>
                <div className={styles.title}>{t("首个亚非支付平台")}</div>
                <div className={styles.describe}>
                  {t("使移动支付平台与银行账户之间的操作更加便利。减少非洲和亚洲之间的支票、现金和银行转账支付的人工流程。")}
                </div>
                <div className={styles.btnGroup}>
                  <a href="https://apps.apple.com/us/app/wapipay-global/id1532846160" target="_blank" style={{ marginRight: 20 }}>
                    <div className={styles.downLoadBtn}>
                      <img className={styles.icon} src={iconApple} alt="" />
                      <div className={styles.btnText}>
                        <div className={styles.downLoadText}>Download on the</div>
                        <div className={styles.platform}>App Store</div>
                      </div>
                    </div>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.wapipay" target="_blank">
                    <div className={styles.downLoadBtn}>
                      <img className={styles.icon} src={iconGoogle} alt="" />
                      <div className={styles.btnText}>
                        <div className={styles.downLoadText}>Get it on</div>
                        <div className={styles.platform}>Google</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          </Col>
          <Col xs={3} lg={4}></Col>
        </Row>

      </div>

      <div className={styles.content3}>
        <div className={styles.answerTitle}>{t('让我们来回答你的一些问题')}</div>
        <Row style={{ width: "100%" }}>
          <Col xs={0} lg={6}></Col>
          <Col xs={24} lg={12}>
            <Collapse expandIconPosition="right" bordered={false}>
              <Panel header={t('Wapi Pay 是什么？')} key="1" className={styles.answerT}>
                <p className={styles.answerP}>{t('Wapi Pay 是首家领先的亚非金融解决方案提供商。\nWapi Pay 通过其技术为各种平台集成和虚拟账户(钱包)提供平台，为合作伙伴和客户提供针对个人、商户和企业的便捷全球支付和金融科技服务。')}</p>
              </Panel>
            </Collapse>

            <Collapse expandIconPosition="right" bordered={false}>
              <Panel header={t('为什么我的付款失败？')} key="1" className={styles.answerT}>
                <p className={styles.answerP}>{t('对支付宝的交易 -')}</p>
                <ul className={styles.answerUl}>
                  <li>{t('与支付宝账户的关联银行卡可能已经过期')}</li>
                  <li>{t('这支付宝账户还没有关联一张银行卡')}</li>
                  <li>{t('您的银行账户还是 M-PESA 账户没有足够的资金')}</li>
                  <li>{t('您已达到每日交易限额')}</li>
                </ul>
              </Panel>
            </Collapse>

            <Collapse expandIconPosition="right" bordered={false}>
              <Panel header={t('Wapi Pay 的安全怎么样？')} key="1" className={styles.answerT}>
                <p className={styles.answerP}>{t('Wapi Pay 非常安全。')}</p>
              </Panel>
            </Collapse>

            <Collapse expandIconPosition="right" bordered={false}>
              <Panel header={t('Wapi Pay 是否合规？')} key="1" className={styles.answerT}>
                <p className={styles.answerP}>{t('Wapi Pay 已在新加坡金融管理局注册并提交应用程序从肯尼亚中央银行收到汇款许可。')}</p>
              </Panel>
            </Collapse>
            <Collapse expandIconPosition="right" bordered={false}>
              <Panel header={t('我能取消我的订单吗?')} key="1" className={styles.answerT}>
                <p className={styles.answerP}>{t('对于通过 M-PESA 支付的订单，由于金额会在很短的时间内记入收款人，因此无法取消交易。\n对于通过银行支付的订单，您可以在提交后1个小时内取消订单。 一小时后，我们将努力取回客户的支付费用')}</p>
              </Panel>
            </Collapse>
            <Collapse expandIconPosition="right" bordered={false}>
              <Panel header={t('我输入了错误的收款人信息。')} key="1" className={styles.answerT}>
                <p className={styles.answerP}>{t('我们鼓励您输入正确的收款人信息。但是，如果您发现信息不正确，可以采取以下步骤。')}</p>
                <ul className={styles.answerUl}>
                  <li>{t('尽快取消订单并完成新订单')}</li>
                  <li>{t('如果收款方账户信息不匹配，订单将自动失败。然后你可以发起一个新的交易')}</li>
                </ul>
              </Panel>
            </Collapse>
          </Col>
          <Col xs={0} lg={6}></Col>
        </Row>
      </div>
      {isModalVisible && <Modal
        title={t("我的钱包")}
        setIsModalVisible={(visible) => {
          setSelectedAccountId(undefined)
          setIsModalVisible(visible)
        }}
        maskClosable={false}
        wrapClassName={styles.accountsRoot}
        width={520}
      >
        <div className='login-form-container'>
          <Form
            className="login-form"
          >
            <div className={styles.formBox}>
              <DefaultSelect
                formItemName="accountId"
                formItemLabel={t("支付账户")}
                onChange={(val, opt) => {
                  setSelectedAccountId(val)
                }}
              >
                {payoutAccounts.map(account => {
                  const title = `${account.name} (${t("可用余额")} ${formatCurrency(account.balance || 0)} ${account.currency})`
                  return <OptGroup label={account.name} key={account.id}>
                    <Option
                      value={account.id}
                    >
                      <span title={title}>{title}</span>
                    </Option>
                    {account.subAccounts?.map((subAccount) => {
                      const subTitle = `${subAccount.name} (${t("可用余额")} ${formatCurrency(subAccount.balance || 0)} ${subAccount.currency})`
                      return <Option
                        value={subAccount.id}
                        key={subAccount.id}
                      >
                        <span title={subTitle}>{subTitle}</span>
                      </Option>
                    }
                    )}
                  </OptGroup>
                })}
              </DefaultSelect>
            </div>

            <div className={styles.btnWarp}>
              <Button
                disabled={!Boolean(selectedAccountId)}
                type="primary"
                loading={isLoading}
                className='submit-button'
                onClick={() => {
                  history.push({
                    pathname: `/myAccounts/${selectedAccountId}/quickpay`,
                    state: {
                      sendingAmount: formatToNumber(sendingAmount),
                      instAmount: formatToNumber(instAmount),
                      sendingCurrency,
                      instCurrency,
                      formatSend: sendingAmount,
                      formatInst: instAmount,
                    }
                  });
                }}
              >
                {t("下一步")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>}
    </div>
  );
};

export default Homepage;
