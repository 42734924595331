import $request from '@/request';

export interface ICommonData {
  code: number;
  msg?: string;
}

export interface IExchangeRateParams {
  localCurrency: string;
  foreignCurrency: string;
  custType: any;
}

export interface IExchangeRateRows {
  snippet: null,
  searchValue: null,
  createBy: string,
  createTime: string,
  updateBy: string,
  updateTime: string,
  remark: null,
  params: any,
  pd: null,
  cityId: null,
  id: number,
  localCurrency: string,
  foreignCurrency: string,
  custType: string,
  location: string,
  fromAmount: number,
  toAmount: number,
  exchangeRate: number,
  processDateTime: null,
  resultCode: null,
  comments: string,
  amount: null,
  commissionPercentage: number,
  exchangeRatePercentage: number,
  paymentMethod: null,
  deliveryMethod: null
}

export interface IExchangeRateData extends ICommonData {
  total: number;
  list: IExchangeRateRows[];
}

export const queryExchangeRate = (d: IExchangeRateParams): Promise<IExchangeRateData> => {
  return $request.post('/remit-service/remit/rate/list/page', {
    ...d, custType: d.custType ?? 1,
    pd: {
      pageSize: 1,
      pageNum: 1,
      orderByColumn: 'create_time',
      isAsc: 'desc'
    }
  })
}


export interface IPaymentParams {
  beneficiaryNo: string;
  commission: number;
  deliveryMethod: number;
  paymentMethod: number;
  exchangeRate: number;
  fomoBeneRefNo?: string;
  sendingAmount: number;
  sendingCurrency: string;
  instAmount: number;
  instCurrency: string;
  sourceOfFund?: number;
  remitPurpose?: number;
  totalAmount: number;
  trnType: string;
  trnSource: number;
  productDescription?: string;
  productPictures: string;
}

export interface IPaymentData extends ICommonData {
  trnNo: string;
  id: number;
}

export interface IPayMPESAParams {
  trnNo: string;
  phone: string;
}

export const payBankToBank = (data: IPaymentParams): Promise<IPaymentData> => {
  return $request.post('/remit-service/remit/transaction/kebank2cnbank/submit', data)
}

export const payMPESAToBank = (data: IPaymentParams): Promise<IPaymentData> => {
  return $request.post('/remit-service/remit/transaction/mpesa2bank/submit', data)
}

export const payCnBank2KeBbank = (data: IPaymentParams): Promise<IPaymentData> => {
  return $request.post('/remit-service/remit/transaction/cnbank2kebank/submit', data)
}

export const payKeBank2CnBank = (data: IPaymentParams): Promise<IPaymentData> => {
  return $request.post('/remit-service/remit/transaction/kebank2cnbank/submit', data)
}

export const transactionPayMPESA = (d: IPayMPESAParams): Promise<ICommonData> => {
  return $request.post(`/remit-service/remit/transaction/mpesa/pay/${d.trnNo}`, d.phone, {
    headers: { 'Content-Type': 'application/json' }
  })
}

export interface IUpdateBankRefNOParams {
  trnNo: string;
  bankRefNo: string;
}

export const updateBankRefNO = (data: IUpdateBankRefNOParams): Promise<ICommonData> => {
  return $request.post(`/remit-service/remit/transaction/update/bankRefNo`, data)
}
