
import UnitedStates from '@/assets/image/login/UnitedStates.png'
import China from '@/assets/image/login/China.png'
import Kenya from '@/assets/image/login/Kenya.png'
import LocalBank from '@/assets/image/recipient/local-bank.png'
import Wechat from '@/assets/image/recipient/wechat.png'
import MPESA from '@/assets/image/recipient/m-pesa.png'
import Uganda from '@/assets/image/login/Uganda.png'
import Ghana from '@/assets/image/login/Ghana.png'
import JPY from '@/assets/image/login/JPY.png'
import INR from '@/assets/image/login/INR.png'
import IDR from '@/assets/image/login/IDR.png'
import VND from '@/assets/image/login/VND.png'
import MYR from '@/assets/image/login/MYR.png'
import PHP from '@/assets/image/login/PHP.png'
import TWD from '@/assets/image/login/TWD.png'
import Naira from '@/assets/image/login/Naira.png'
import ZAR from '@/assets/image/login/ZAR.png'
import EGP from '@/assets/image/login/EGP.png'
import ETB from '@/assets/image/login/ETB.png'
import GBP from '@/assets/image/login/GBP.png'
import Zambia from '@/assets/image/login/Zambia.png'
import AliPay from "@/assets/image/order/alipay.svg";
import Binance from "@/assets/image/remittance/binance.png";
import { Nationality } from '@/api/auth/auth'

export const countryOptions = [
	{ code: '+86', label: 'China (中国)', nationality: 'Chinese', icon: China, value: 86, codeVal: Nationality.China, currency: 'CNY' },
	{ code: '+254', label: 'Kenya', nationality: 'Kenyan', icon: Kenya, value: 254, codeVal: Nationality.Kenya, currency: 'KES' },
	{ code: '+1', label: 'United States', nationality: 'American', icon: UnitedStates, value: 1, codeVal: Nationality.America, currency: 'USD' },
	{ code: '+256', label: 'Uganda', nationality: 'Ugandan', icon: Uganda, value: 256, codeVal: Nationality.Uganda, currency: 'UGX' },
	{ code: '+233', label: 'Ghana', nationality: 'Ghanaian', icon: Ghana, value: 233, codeVal: Nationality.Ghana, currency: 'GHS' },
	{ code: '+260', label: 'Zambia', nationality: 'Zambian', icon: Zambia, value: 260, codeVal: Nationality.Zambia, currency: 'ZMW' },
	{ code: '+81', label: 'Japan', nationality: 'Japanese', icon: JPY, value: 81, codeVal: Nationality.Japan, currency: 'JPY' },
	{ code: '+91', label: 'India', nationality: 'Indian', icon: INR, value: 91, codeVal: Nationality.India, currency: 'INR' },
	{ code: '+62', label: 'Indonesia', nationality: 'Indonesian', icon: IDR, value: 62, codeVal: Nationality.Indonesia, currency: 'IDR' },
	{ code: '+84', label: 'Vietnam', nationality: 'Vietnamese', icon: VND, value: 84, codeVal: Nationality.Vietnam, currency: 'VND' },
	{ code: '+60', label: 'Malaysia', nationality: 'Malaysian', icon: MYR, value: 60, codeVal: Nationality.Malaysia, currency: 'MYR' },
	{ code: '+63', label: 'Philippines', nationality: 'Filipino', icon: PHP, value: 63, codeVal: Nationality.Philippines, currency: 'PHP' },
	{ code: '+886', label: 'Taiwan', nationality: 'Taiwanese', icon: TWD, value: 886, codeVal: Nationality.Taiwan, currency: 'TWD' },
	{ code: '+234', label: 'Nigeria', nationality: 'Nigerian', icon: Naira, value: 234, codeVal: Nationality.Nigeria, currency: 'NGN' },
	{ code: '+27', label: 'South Africa', nationality: 'South African', icon: ZAR, value: 27, codeVal: Nationality['South Africa'], currency: 'ZAR' },
	{ code: '+20', label: 'Egypt', nationality: 'Egyptian', icon: EGP, value: 20, codeVal: Nationality.Egypt, currency: 'EGP' },
	{ code: '+251', label: 'Ethiopia', nationality: 'Ethiopian', icon: ETB, value: 251, codeVal: Nationality.Ethiopia, currency: 'ETB' },
	{ code: '+44', label: 'United Kingdom', nationality: 'British', icon: GBP, value: 44, codeVal: Nationality.British, currency: 'GBP' },
]

export const receiveTypes = [
	{ label: 'Local Bank', icon: LocalBank, value: 1 },
	{ label: 'Wechat', icon: Wechat, value: 2 },
	{ label: 'M-pesa', icon: MPESA, value: 3 },
]

export const deliveryOptions = [
	{ value: 3, label: "Bank Transfer", disabled: false, icon: LocalBank },
	{ value: 2, label: "M-pesa", disabled: false, icon: MPESA },
	{ value: 1, label: "Wechat pay", disabled: true, icon: Wechat },
	{ value: 4, label: "Alipay", disabled: true, icon: AliPay },
	{ value: 5, label: "Binance Pay", disabled: true, icon: Binance },
]

export enum QuickPayDelivery {
	'WapipayAccount' = 1,
	'MPesa' = 2,
	'MTN' = 3,
	'Airtel' = 4,
	'LocalBank' = 5,
}

export const quickPayRecipientNation: Record<string, string> = {
	CNY: '1',
	KES: '2',
	GHS: '3',
	UGX: '4',
	USD: '5',
	ZMW: '6',
	JPY: '7',
	INR: '8',
	IDR: '9',
	VND: '10',
	MYR: '11',
	PHP: '12',
	TWD: '13',
	NGN: '14',
	ZAR: '15',
	EGP: '16',
	ETB: '17',
	GBP: '18'
}

export const quickPayDeliveryOptions = [
	{ value: QuickPayDelivery.WapipayAccount, label: "Wapipay account" },
	{ value: QuickPayDelivery.MPesa, label: "M-pesa" },
	{ value: QuickPayDelivery.MTN, label: "MTN" },
	{ value: QuickPayDelivery.Airtel, label: "Airtel" },
	{ value: QuickPayDelivery.LocalBank, label: "Local Bank" },
]

export const quickPayCountryOptions = [
	{ code: '+254', label: 'Kenya', nationality: 'Kenyan', icon: Kenya, value: 254, codeVal: 2, currency: 'KES' },
	{ code: '+86', label: 'China', nationality: 'Chinese', icon: China, value: 86, codeVal: 1, currency: 'CNY' },
	{ code: '+256', label: 'Uganda', nationality: 'Ugandan', icon: Uganda, value: 256, codeVal: 4, currency: 'UGX' },
	{ code: '+1', label: 'United States', nationality: 'American', icon: UnitedStates, value: 1, codeVal: 5, currency: 'USD' },
	{ code: '+44', label: 'United Kingdom', nationality: 'British', icon: GBP, value: 44, codeVal: Nationality.British, currency: 'GBP' },
	{ code: '+234', label: 'Nigeria', nationality: 'Nigerian', icon: Naira, value: 234, codeVal: Nationality.Nigeria, currency: 'NGN' },
]

export const quickPayExchangeRateOptions = [
	{ value: "CNY", label: "CNY", disabled: false, remarks: "China (中国)", icon: China },
	{ value: "KES", label: "KES", disabled: false, remarks: "Kenya", icon: Kenya },
	{ value: "USD", label: "USD", disabled: false, remarks: "United States", icon: UnitedStates },
	{ value: "GBP", label: "GBP", disabled: false, remarks: "United Kingdom", icon: GBP },
	{ value: "UGX", label: "UGX", disabled: false, remarks: "Uganda", icon: Uganda },
	{ value: "NGN", label: "NGN", disabled: false, remarks: "Nigeria", icon: Naira },

	{ value: "ZMW", label: "ZMW", disabled: true, remarks: "Zambia", icon: Zambia },
	{ value: "GHS", label: "GHS", disabled: true, remarks: "Ghana", icon: Ghana },
	{ value: "JPY", label: "JPY", disabled: true, remarks: "Japan", icon: JPY },
	{ value: "INR", label: "INR", disabled: true, remarks: "India", icon: INR },
	{ value: "IDR", label: "IDR", disabled: true, remarks: "Indonesia", icon: IDR },
	{ value: "VND", label: "VND", disabled: true, remarks: "Vietnam", icon: VND },
	{ value: "MYR", label: "MYR", disabled: true, remarks: "Malaysia", icon: MYR },
	{ value: "PHP", label: "PHP", disabled: true, remarks: "Philippines", icon: PHP },
	{ value: "TWD", label: "TWD", disabled: true, remarks: "Taiwan", icon: TWD },
	{ value: "ZAR", label: "ZAR", disabled: true, remarks: "South Africa", icon: ZAR },
	{ value: "EGP", label: "EGP", disabled: true, remarks: "Egypt", icon: EGP },
	{ value: "ETB", label: "ETB", disabled: true, remarks: "Ethiopia", icon: ETB },
];

// export const individualMonetaryAttribute = {
// 	USD: { max: 4000 },
// 	KES: { max: 500000 },
// 	UGX: { max: 15000000 },
// 	CNY: { max: 30000 },
// 	GBP: { max: 3000 },
// 	EUR: { max: 3500 },
// 	NGN: { max: 3000000 },
// 	JPY: { max: 630000 },
// 	TZS: { max: 10000000 },
// 	INR: { max: 330000 }
// }

// // Type: Individual customer. Delivery method: Wapi Account
// export const individualWapiAccount = {
// 	USD: { perMax: 2000 },
// 	KES: { perMax: 250000 },
// 	UGX: { perMax: 7500000 },
// 	CNY: { perMax: 15000 },
// 	GBP: { perMax: 1500 },
// 	EUR: { perMax: 1750 },
// 	NGN: { perMax: 1500000 },
// 	JPY: { perMax: 315000 },
// 	TZS: { perMax: 5000000 },
// 	INR: { perMax: 165000 }
// }

// // Type: Individual customer. Delivery method: Bank or Mobile wallet
// export const individualOtherPayMethod = {
// 	USD: { perMax: 1200 },
// 	KES: { perMax: 150000 },
// 	UGX: { perMax: 4500000 },
// 	CNY: { perMax: 8700 },
// 	GBP: { perMax: 940 },
// 	EUR: { perMax: 1100 },
// 	NGN: { perMax: 935000 },
// 	JPY: { perMax: 190000 },
// 	TZS: { perMax: 3100000 },
// 	INR: { perMax: 100000 }
// }

export const businessMonetaryAttribute = {
	USD: { max: 1000000 },
	KES: { max: 100000000 },
	UGX: { max: 3700000000 },
	CNY: { max: 7300000 },
	GBP: { max: 785000 },
	EUR: { max: 930000 },
	NGN: { max: 780000000 },
	JPY: { max: 157000000 },
	TZS: { max: 2600000000 },
	INR: { max: 84000000 }
}


// Type: Business customer. Delivery method: Wapi Account
export const businessWapiAccount = {
	USD: { perMax: 8000 },
	KES: { perMax: 1000000 },
	UGX: { perMax: 30000000 },
	CNY: { perMax: 58000 },
	GBP: { perMax: 6200 },
	EUR: { perMax: 7400 },
	NGN: { perMax: 6200000 },
	JPY: { perMax: 1200000 },
	TZS: { perMax: 20000000 },
	INR: { perMax: 670000 }
}


// Type: Business customer. Delivery method: Bank or Mobile wallet
export const businessOtherPayMethod = {
	USD: { perMax: 8000 },
	KES: { perMax: 1000000 },
	UGX: { perMax: 30000000 },
	CNY: { perMax: 58000 },
	GBP: { perMax: 6200 },
	EUR: { perMax: 7400 },
	NGN: { perMax: 6200000 },
	JPY: { perMax: 1200000 },
	TZS: { perMax: 20000000 },
	INR: { perMax: 670000 }
}

export const exchangeRateOptions = [
	{ value: "CNY", label: "CNY", disabled: false, remarks: "China (中国)", icon: China, support: true },
	{ value: "KES", label: "KES", disabled: false, remarks: "Kenya", icon: Kenya, support: true },
	{ value: "USD", label: "USD", disabled: false, remarks: "United States", icon: UnitedStates },
	{ value: "ZMW", label: "ZMW", disabled: false, remarks: "Zambia", icon: Zambia },
	{ value: "UGX", label: "UGX", disabled: false, remarks: "Uganda", icon: Uganda },
	{ value: "GHS", label: "GHS", disabled: false, remarks: "Ghana", icon: Ghana },
	{ value: "JPY", label: "JPY", disabled: false, remarks: "Japan", icon: JPY },
	{ value: "INR", label: "INR", disabled: false, remarks: "India", icon: INR },
	{ value: "IDR", label: "IDR", disabled: false, remarks: "Indonesia", icon: IDR },
	{ value: "VND", label: "VND", disabled: false, remarks: "Vietnam", icon: VND },
	{ value: "MYR", label: "MYR", disabled: false, remarks: "Malaysia", icon: MYR },
	{ value: "PHP", label: "PHP", disabled: false, remarks: "Philippines", icon: PHP },
	{ value: "TWD", label: "TWD", disabled: false, remarks: "Taiwan", icon: TWD },
	{ value: "NGN", label: "NGN", disabled: false, remarks: "Nigeria", icon: Naira },
	{ value: "ZAR", label: "ZAR", disabled: false, remarks: "South Africa", icon: ZAR },
	{ value: "EGP", label: "EGP", disabled: false, remarks: "Egypt", icon: EGP },
	{ value: "ETB", label: "ETB", disabled: false, remarks: "Ethiopia", icon: ETB },
	{ value: "GBP", label: "GBP", disabled: false, remarks: "United Kingdom", icon: GBP },
];

export const myAccountsExchangeRateOptions = [
	{ value: "CNY", label: "CNY", disabled: false, remarks: "China (中国)", icon: China, support: true },
	{ value: "KES", label: "KES", disabled: false, remarks: "Kenya", icon: Kenya, support: true },
	{ value: "USD", label: "USD", disabled: false, remarks: "United States", icon: UnitedStates, support: true },
	{ value: "GBP", label: "GBP", disabled: false, remarks: "United Kingdom", icon: GBP, support: true },
	{ value: "UGX", label: "UGX", disabled: false, remarks: "Uganda", icon: Uganda, support: true },
	{ value: "NGN", label: "NGN", disabled: true, remarks: "Nigeria", icon: Naira, support: true },

	{ value: "ZMW", label: "ZMW", disabled: true, remarks: "Zambia", icon: Zambia },
	{ value: "GHS", label: "GHS", disabled: true, remarks: "Ghana", icon: Ghana },
	{ value: "JPY", label: "JPY", disabled: true, remarks: "Japan", icon: JPY },
	{ value: "INR", label: "INR", disabled: true, remarks: "India", icon: INR },
	{ value: "IDR", label: "IDR", disabled: true, remarks: "Indonesia", icon: IDR },
	{ value: "VND", label: "VND", disabled: true, remarks: "Vietnam", icon: VND },
	{ value: "MYR", label: "MYR", disabled: true, remarks: "Malaysia", icon: MYR },
	{ value: "PHP", label: "PHP", disabled: true, remarks: "Philippines", icon: PHP },
	{ value: "TWD", label: "TWD", disabled: true, remarks: "Taiwan", icon: TWD },
	{ value: "ZAR", label: "ZAR", disabled: true, remarks: "South Africa", icon: ZAR },
	{ value: "EGP", label: "EGP", disabled: true, remarks: "Egypt", icon: EGP },
	{ value: "ETB", label: "ETB", disabled: true, remarks: "Ethiopia", icon: ETB },
];

export const allowCurrency = ['KES', 'CNY']

export enum AccountType {
	PayoutAccount = 'PayoutAccount',
	PayoutSubAccount = 'PayoutSubAccount',
	CollectionAccount = 'CollectionAccount',
	CollectionSubAccount = 'CollectionSubAccount',
}