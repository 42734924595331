import React, { Component, useEffect } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import './App.css'
import Index from './pages/index'
import Login from './pages/Login/Login'
import Register from './pages/Register/Register'
import RegisterSuccess from './pages/Register/RegisterSuccess/RegisterSuccess'
import CreatAccount from './pages/CreatAccount/CreatAccount'
import InvitationPage from './pages/InvitationPage'
import InvitationSuccess from './pages/InvitationPage/InvitationSuccess/InvitationSuccess'
import ForgetPassword from "./pages/ForgetPassword";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { DELETE_USER_INFO } from '@/redux/action'
import { useMappedState, useDispatch } from "redux-react-hook";
import { IState } from '@/redux/interface'
import { createHashHistory } from 'history';
import { useTranslation } from 'react-i18next';
import zh_CN from 'antd/es/locale-provider/zh_CN';
import en_US from 'antd/es/locale-provider/en_US';
import { ConfigProvider } from 'antd';
import { getToken } from './request'
import { userApi } from "@/api";
import { getUserInfo } from './api/user/user'
import { message } from 'antd'
import i18next from "i18next";
import { signOut } from './utils/utils'
import { SAVE_USER_INFO } from './redux/action'

export const history = createHashHistory();

const AppRoute = () => {

  const userInfo = useMappedState((state: IState) => state.UserInfoReducer.userInfo);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = history.location

  useEffect(() => {
    let local = localStorage.getItem('wapipay-website-locale');
    if (local) {
      i18n.changeLanguage(local)
    } else {
      i18n.changeLanguage('zh')
      setTimeout(() => {
        window.localStorage.setItem('wapipay-website-locale', 'zh');
      }, 0)
    }
  }, [])
  useEffect(() => {
    const cookies = document.cookie
    const userInfo = window.localStorage.getItem('userInfo')
    if (!cookies || !userInfo) {
      dispatch({ type: DELETE_USER_INFO })
      // history.push('/login')
    }
    if (location.pathname.length <= 1) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    getNewUserInfo()
  }, [])

  const getNewUserInfo = async () => {
    try {
      const token = getToken()
      if (token) {
        const res = await userApi.getUserInfo()
        dispatch({ type: SAVE_USER_INFO, userInfo: res })
        window.localStorage.setItem('userInfo', JSON.stringify(res))
      }
    } catch (error) {
      message.warning(i18next.t("用户信息解析失败，请重新登录！"))
      signOut()
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <ConfigProvider locale={i18n.language === 'en' ? en_US : zh_CN}>
      <Router history={history}>
        <Switch>
          <Route exact path='/login' component={Login}></Route>
          <Route exact path='/register' component={Register}></Route>
          <Route exact path='/creatAccount' component={CreatAccount}></Route>
          <Route exact path='/registerSuccess' component={RegisterSuccess}></Route>
          <Route exact path='/forgetPassword' component={ForgetPassword}></Route>
          <Route exact path='/terms' component={Terms}></Route>
          <Route exact path='/privacyPolicy' component={PrivacyPolicy}></Route>
          <Route exact path='/invitation' component={InvitationPage}></Route>
          <Route exact path='/invitationSuccess' component={InvitationSuccess}></Route>
          <Route path='/' component={Index}></Route>
        </Switch>
      </Router>
    </ConfigProvider>
  )
}

export default AppRoute