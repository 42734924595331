import React, { FC, useEffect, useState } from 'react'
import { useMappedState } from "redux-react-hook";
import type { IState } from "@/redux/interface";
import Failed from "@/assets/image/order/failed.svg";
import Warning from "@/assets/image/order/waiting.svg";
import Processing from "@/assets/image/order/processing.svg";
import Complete from "@/assets/image/order/complete.svg";
import Cancelled from "@/assets/image/order/cancelled.svg";
import CNY from "@/assets/image/order/cny.png";
import KES from "@/assets/image/order/kes.png";
import USD from "@/assets/image/order/usd.png";
import { history } from "@/route";
import { isMobile } from "@/utils/utils";
import NoDataCom from "@/components/NoDataCom";
import PcTable from "./components/PcTable/PcTable";
import OrderDetail from "./components/OrderDetail/OrderDetail";
import H5Table from "./components/H5Table/H5Table";
import { useTranslation } from "react-i18next";
import styles from "./Order.module.scss";
import { RegistrationStatus } from '@/api/auth/auth'

interface IOrderProps { }

export interface Status {
  'Failed': string;
  'Waiting': string;
  'Processing': string;
  'Complete': string;
  'Cancelled': string;
  CNY: string;
  USD: string;
  KES: string;
  [key: string]: string;
}

export const status: Status = {
  'Failed': Failed,
  'Waiting': Warning,
  'Processing': Processing,
  'Complete': Complete,
  'Cancelled': Cancelled,
  CNY: CNY,
  USD: USD,
  KES: KES,
}

export const color = {
  Waiting: '#FF9900',
  Processing: '#3279E5',
  Failed: '#FF5C00',
  Complete: '#0FC000',
  Cancelled: '#7F68FB',
}

const Order: FC<IOrderProps> = (props) => {
  const { t } = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const [isDetail, setIsDetail] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  const [refreshkey, setRefreshkey] = useState(1)

  const getParams = (u: string) => {
    const params: Record<string, string | undefined> = {}
    const str = u.substring(1)
    str.split('&').forEach(v => {
      const [key, value] = v.split("=")
      params[key] = value
    })
    return params.id
  }
  const returnState = (status: string) => {
    const v = +status ?? 0;
    let s = '';
    if ([0, 1].includes(v)) {
      s = 'Waiting'
    } else if ([2, 3, 7].includes(v)) {
      s = 'Processing'
    } else if ([4, 10, -1, 12].includes(v)) {
      s = 'Failed'
    } else if ([8, 11].includes(v)) {
      s = 'Complete'
    } else if ([9].includes(v)) {
      s = 'Cancelled'
    }
    return s
  }

  useEffect(() => {
    const { location: { search, pathname }, listen } = history;
    if (pathname === '/order') setIsDetail(!!getParams(search))
    const unListen = listen(location => {
      const isOrder = location.pathname === '/order'
      if (!isOrder) unListen && unListen()
      setIsDetail(isOrder ? !!getParams(location.search) : false);
    })
  }, [])

  const renderPage = () => !isEmpty || userInfoState?.registrationStatus !== RegistrationStatus.Finished ?
    <NoDataCom
      title={t('没有订单')}
      subtitle={t('您目前没有订单，请汇款以添加订单')}
      btnTitle={t('开始汇款')}
      btnClick={() => {
        history.push('/remittance')
      }}
    /> :
    (isMobile() ?
      <H5Table status={status} color={color} getStatus={returnState} setEmptyStatus={(s) => setIsEmpty(s)} /> :
      <PcTable status={status} color={color} getStatus={returnState} setEmptyStatus={(s) => setIsEmpty(s)} />
    )
  return (
    <div>
      <div key={`order_table_key${refreshkey}`} className={isDetail ? styles.tableContainer : ''}>{renderPage()}</div>
      {isDetail && <OrderDetail
        status={status}
        getParamsId={getParams}
        getStatus={returnState}
        setTableKey={(key) => setRefreshkey(key)} />}
    </div>
  )
}

export default Order;
