import $request from '@/request';

export interface IConfigurationParams {
  name?: string;
}

export interface IConfigurationRow {
  confCode: number;
  id: number;
  langCode: number;
  locale: string;
  name: string;
  text: string;
  icon: null | string;
}

export interface IConfigurationData {
  total: number;
  code: number;
  list: IConfigurationRow[];
  msg?: string;
}

export interface ISysParamsData {
  code: number;
  data: any;
}

export const getConfiguration = (d: IConfigurationParams): Promise<IConfigurationData> => {
  return $request.post('/remit-service/remit/configuration/list', d)
}

export const getRemitSysParam = (): Promise<ISysParamsData> => {
  return $request.get('/remit-service/remit/rate/getRemitSysParam')
}
