import React, { FC, useRef } from 'react'
import styles from './RateInputSelect.module.scss'
import { Row, Col, Select, message } from 'antd'
import { CounterInput } from '@/components/Input/Input'
import useSize from "@/utils/useSize";
import turnDown from "@/assets/components/turn-down.png";
import { quickPayExchangeRateOptions } from "@/utils/defaultData";
import { useTranslation } from "react-i18next";


interface IRateInputSelectProps {
  style?: React.CSSProperties;
  placeholder?: string;
  className?: string;
  inputValue?: string;
  type?: string;
  inputType?: string;
  onInputChange: (e: any) => void;
  onInputBlur?: () => void;
  onInputFocus?: () => void;
  onSelectChange: (e: string) => void;
  defaultSelect: string | number;
}

const { Option } = Select;

export const RateInputSelect: FC<IRateInputSelectProps> = (props) => {
  const { inputType = 'number', inputValue, placeholder, onInputChange, onSelectChange, onInputBlur, onInputFocus, defaultSelect } = props
  const ref1 = useRef<HTMLDivElement>(null);
  const { width: w1 } = useSize(ref1);
  const ref2 = useRef<HTMLDivElement>(null);
  const { width: w2 } = useSize(ref2);
  const { t } = useTranslation();

  const optionItem = (v: any, className: string, isOption: boolean, i?: number) =>
    <div className={`flex-center ${className} ${v.disabled && styles.disabled} ${i === 5 && styles.group}`}>
      <img src={v.icon} alt="" />
      <span className={styles.label}>{v.label}</span>
      {isOption ? <span>{v.remarks}</span> : <img className={styles.turnDownIcon} src={turnDown} alt="" />}
    </div>
  return (
    <div className={styles.wrap}>
      <Row>
        <Col className={styles.inputWrap} xs={14} sm={15} ref={ref1}>
          <CounterInput
            isControl={true}
            value={inputValue}
            onChange={e => onInputChange(e)}
            onBlur={onInputBlur}
            onFocus={onInputFocus}
            inputType={inputType}
            placeholder={placeholder} />
        </Col>
        <Col className={styles.selectWrap} xs={10} sm={9} ref={ref2}>
          <Select
            optionLabelProp="label"
            bordered={false}
            getPopupContainer={triggerNode => {
              return triggerNode?.parentNode?.parentNode?.parentNode || document.body
            }}
            placement='bottomRight'
            dropdownMatchSelectWidth={(w1 ?? 0) + (w2 ?? 0) + 2}
            dropdownStyle={{ right: 0 }}
            value={defaultSelect}
            listHeight={400}
            showArrow={false}
            onChange={(f) => {
              const e = quickPayExchangeRateOptions.filter(t => t.value === f)[0]
              onSelectChange(e.value)
            }}
          >
            {
              quickPayExchangeRateOptions.map((v, i) =>
                <Option
                  value={v.value}
                  key={v.value}
                  disabled={v.disabled}
                  label={optionItem(v, styles.opValue, false)}>
                  {optionItem(v, styles.opItem, true, i)}
                </Option>)
            }
          </Select>
        </Col>
      </Row>
    </div>
  )
}
